import axiosClient from "../axios";

class MistakeTicketService {
    approve(values) {
        return axiosClient.post('/mistake_tickets/approvals', values);
    }
    lists() {
        return axiosClient.get('/mistake_tickets/list');
    }
    //to get all data
    view(page, rowsPerPage) {
        return axiosClient.get(`/mistake_tickets?page=${page}&pp=${rowsPerPage}`);
    }
    //to store data
    store(values) {
        return axiosClient.post("/mistake_tickets", values);
    }
    //to update data
    update(uuid, values) {
        return axiosClient.put(`/mistake_tickets/${uuid}`, values);
    }
    // to simple search 
    search(url) {
        return axiosClient.get(`/mistake_tickets?${url}`);
    }
    //to search single data
    singleView(uuid) {
        return axiosClient.get(`/mistake_tickets/${uuid}`);
    }
    //to delete single data
    delete(uuid) {
        return axiosClient.delete("/mistake_tickets/" + uuid, { entry_id: uuid });
    }
    //to restore single data
    restore(uuid) {
        return axiosClient.delete(`/mistake_tickets/${uuid}/restore`);
    }
    //to prune single data
    prune(uuid) {
        return axiosClient.delete(`/mistake_tickets/${uuid}/prune`);
    }
    //to delete selected row data
    deleteAll(selectedRowsUid) {
        return axiosClient.post("/mistake_tickets/deleteall", { 'ids': selectedRowsUid });
    }
    //to restore selected row data
    restoreAll(selectedRowsUUID) {
        return axiosClient.post("/mistake_tickets/restoreall", { 'ids': selectedRowsUUID });
    }
    //to prune selected row data
    pruneAll(selectedRowsUUID) {
        return axiosClient.post("/mistake_tickets/pruneall", { 'ids': selectedRowsUUID });
    }
    //to get deleted data
    viewDeleted() {
        return axiosClient.get("/mistake_tickets");
    }
}
export default new MistakeTicketService();