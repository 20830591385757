import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
} from "react-bootstrap";
import { usePermissionContext } from "../../../../context/PremissionContext";
import { GrSystem } from "react-icons/gr";
import { MdAdminPanelSettings } from "react-icons/md";
import { FaUserTie, FaWarehouse } from "react-icons/fa6";
import { FcApproval } from "react-icons/fc";
import { GrServices } from "react-icons/gr";
import { HiOutlineDesktopComputer } from "react-icons/hi";
import { RxDashboard } from "react-icons/rx";
import { GiFilmProjector } from "react-icons/gi";

function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = () => {
  const [activeMenu, setActiveMenu] = useState(false);

  const { permissions } = usePermissionContext();

  const companyPermission = permissions.some(permission => permission.name === 'View Company');
  const branchPermission = permissions.some(permission => permission.name === 'View Branch');
  const departmentPermission = permissions.some(permission => permission.name === 'View Department');
  const attributePermission = permissions.some(permission => permission.name === 'View Attribute');
  const fiscalYearPermission = permissions.some(permission => permission.name === 'View Fiscal Year');
  const warehousePermission = permissions.some(permission => permission.name === 'View Warehouse');
  const approvalPermission = permissions.some(permission => permission.name === 'View Approval');
  const rolePermission = permissions.some(permission => permission.name === 'View Role');
  const userPermission = permissions.some(permission => permission.name === 'View User');
  const hrDashboardPermission = permissions.some(permission => permission.name === 'HR Dashboard');
  const employeePermission = permissions.some(permission => permission.name === 'View Employee');
  const hrVendorPermission = permissions.some(permission => permission.name === 'View Employee');
  const hrPurchasePermission = permissions.some(permission => permission.name === 'View Employee');

  const vendorPermission = permissions.some(permission => permission.name === 'View Vendor');
  const rawMaterialPermission = permissions.some(permission => permission.name === 'View Raw Material');
  const menuPermission = permissions.some(permission => permission.name === 'View Menu');
  const packagePermission = permissions.some(permission => permission.name === 'View Package');
  const purchasePermission = permissions.some(permission => permission.name === 'View Purchase Entry');
  const purchaseReturnPermission = permissions.some(permission => permission.name === 'View Purchase Return');
  const billMappingPermission = permissions.some(permission => permission.name === 'Bill Mapping');
  const transferPermission = permissions.some(permission => permission.name === 'Create Item Challan');
  const viewtransferPermission = permissions.some(permission => permission.name === 'View Item Challan');
  const warehouseClosePermission = permissions.some(permission => permission.name === 'View Warehouse Close');

  const operationDashboardPermission = permissions.some(permission => permission.name === 'Operation Dashboard');
  const operationReportPermission = permissions.some(permission => permission.name === 'Operation Report');
  const morStaffPermission = permissions.some(permission => permission.name === 'View Mor Stsale') &&
    permissions.some(permission => permission.name === 'Update Mor Stsale');
  const evenStaffPermission = permissions.some(permission => permission.name === 'View Even Stsale') &&
    permissions.some(permission => permission.name === 'Update Even Stsale');
  const morStockPermission = permissions.some(permission => permission.name === 'View Mor Close') &&
    permissions.some(permission => permission.name === 'Update Mor Close');
  const evenStockPermission = permissions.some(permission => permission.name === 'View Even Close') &&
    permissions.some(permission => permission.name === 'Update Even Close');
  const morCoffeePermission = permissions.some(permission => permission.name === 'View Mor Coffee Close') &&
    permissions.some(permission => permission.name === 'Update Mor Coffee Close');
  const evenCoffeePermission = permissions.some(permission => permission.name === 'View Even Coffee Close') &&
    permissions.some(permission => permission.name === 'Update Even Coffee Close');
  const popcornPermission = permissions.some(permission => permission.name === 'View Popcorn Yield') &&
    permissions.some(permission => permission.name === 'Update Popcorn Yield');
  const shortPunchPermission = permissions.some(permission => permission.name === 'View Short Punch') &&
    permissions.some(permission => permission.name === 'Update Short Punch');
  const stockMistakePermission = permissions.some(permission => permission.name === 'View Stock Mistake') &&
    (permissions.some(permission => permission.name === 'Update Stock Mistake') || permissions.some(permission => permission.name === 'Approve Stock Mistake'));
  const mistakeTicketPermission = permissions.some(permission => permission.name === 'View Mistake Ticket');
  const approvePurchaseEntryPermission = permissions.some(permission => permission.name === 'Approve Purchase Entry');
  const approvePurchaseReturnPermission = permissions.some(permission => permission.name === 'Approve Purchase Return');
  const approveRefundTicketPermission = permissions.some(permission => permission.name === 'Approve Refund Ticket');
  const refundTicketPermission = permissions.some(permission => permission.name === 'View Refund Ticket');

  const complimentaryTicketPermission = permissions.some(permission => permission.name === 'View Complimentary Ticket');
  const approveCompTicketPermission = permissions.some(permission => permission.name === 'Approve Complimentary Ticket');
  const approveMovieSchedulePermission = permissions.some(permission => permission.name === 'Approve Movie Schedule');

  const usernamePermission = permissions.some(permission => permission.name === 'View Employee Username');

  const trailerPermission = permissions.some(permission => permission.name === 'View Trailer');
  const trikonPermission = permissions.some(permission => permission.name === 'View TrikonAd');
  const miscPermission = permissions.some(permission => permission.name === 'View MiscAd');
  const projectionPermission = permissions.some(permission => permission.name === 'Projection Report');
  //location
  let location = useLocation();
  return (
    <>
      <Accordion as="ul" className="navbar-nav iq-main-menu">
        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">Home</span>
            <span className="mini-icon">-</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`${location.pathname === "/app/dashboard" ? "active" : ""
              } nav-link `}
            aria-current="page"
            to="/app/dashboard"
            onClick={() => { }}
          >
            <i className="icon">
              <RxDashboard size={30} />
            </i>
            <span className="item-name">Dashboard</span>
          </Link>
        </li>
        <li>
          <hr className="hr-horizontal" />
        </li>
        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">Settings</span>
            <span className="mini-icon">-</span>
          </Link>
        </li>
        {(
          companyPermission ||
          branchPermission ||
          departmentPermission ||
          attributePermission ||
          fiscalYearPermission ||
          warehousePermission ||
          approvalPermission
        ) && (
            <Accordion.Item
              as="li"
              eventKey="sidebar-settings-system"
              bsPrefix="nav-item"
            >
              <CustomToggle
                eventKey="sidebar-settings-system"
                onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <i className="icon">
                  <GrSystem size={30} />
                </i>
                <span className="item-name">System</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-settings-system">
                <ul className="sub-nav">
                  {companyPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/company" ? "active" : ""
                          } nav-link`}
                        to="/app/company"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> C </i>
                        <span className="item-name">Company</span>
                      </Link>
                    </li>
                  )}
                  {branchPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/branch" ? "active" : ""
                          } nav-link`}
                        to="/app/branch"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> B </i>
                        <span className="item-name">Branches</span>
                      </Link>
                    </li>
                  )}
                  {departmentPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/department" ? "active" : ""
                          } nav-link`}
                        to="/app/department"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> D </i>
                        <span className="item-name">Departments</span>
                      </Link>
                    </li>
                  )}
                  {attributePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/attribute" ? "active" : ""
                          } nav-link`}
                        to="/app/attribute"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> A</i>
                        <span className="item-name"> Attributes</span>
                      </Link>
                    </li>
                  )}
                  {fiscalYearPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/fiscal_year" ? "active" : ""
                          } nav-link`}
                        to="/app/fiscal_year"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> F </i>
                        <span className="item-name">Fiscal Year</span>
                      </Link>
                    </li>
                  )}
                  {warehousePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/warehouse" ? "active" : ""
                          } nav-link`}
                        to="/app/warehouse"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> W </i>
                        <span className="item-name">Warehouse</span>
                      </Link>
                    </li>
                  )}
                  {approvalPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/approval" ? "active" : ""
                          } nav-link`}
                        to="/app/approval"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> A </i>
                        <span className="item-name">Approval</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>
          )}
        {(
          rolePermission ||
          userPermission
        ) && (
            <Accordion.Item
              as="li"
              eventKey="sidebar-settings-admin"
              bsPrefix="nav-item"
            >
              <CustomToggle
                eventKey="sidebar-settings-admin"
                onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <i className="icon">
                  <MdAdminPanelSettings size={30} />
                </i>
                <span className="item-name">Admin</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-settings-admin">
                <ul className="sub-nav">
                  {rolePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/role" ? "active" : ""
                          } nav-link`}
                        to="/app/role"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> R </i>
                        <span className="item-name">Role</span>
                      </Link>
                    </li>
                  )}
                  {userPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/user" ? "active" : ""
                          } nav-link`}
                        to="/app/user"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> U </i>
                        <span className="item-name">User</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>
          )}
        {(
          employeePermission
        ) && (
            <Accordion.Item
              as="li"
              eventKey="sidebar-settings-hr"
              bsPrefix="nav-item"
            >
              <CustomToggle
                eventKey="sidebar-settings-hr"
                onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <i className="icon">
                  <FaUserTie size={30} />
                </i>
                <span className="item-name">Human Resource</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-settings-hr">
                <ul className="sub-nav">
                  <li className="nav-item">
                    <Link
                      className={`${location.pathname === "/app/hr" ? "active" : ""
                        } nav-link`}
                      to="/app/hr"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> E </i>
                      <span className="item-name">HR Dashboard</span>
                    </Link>
                  </li>
                  {employeePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/employee" ? "active" : ""
                          } nav-link`}
                        to="/app/employee"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> E </i>
                        <span className="item-name">Employee</span>
                      </Link>
                    </li>
                  )}
                  <li className="nav-item">
                    <Link
                      className={`${location.pathname === "/app/employee/ticket" ? "active" : ""
                        } nav-link`}
                      to="/app/employee/ticket"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> T </i>
                      <span className="item-name">Ticket Status</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${location.pathname === "/app/hr/vendor" ? "active" : ""
                        } nav-link`}
                      to="/app/hr/vendor"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> T </i>
                      <span className="item-name">Vendor</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${location.pathname === "/app/purchase_entry" ? "active" : ""
                        } nav-link`}
                      to="/app/purchase_entry"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> P </i>
                      <span className="item-name">Purchase Entry</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${location.pathname === "/app/warehouse_close" ? "active" : ""
                        } nav-link`}
                      to="/app/warehouse_close"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> W </i>
                      <span className="item-name">Warehouse Close</span>
                    </Link>
                  </li>
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>
          )}
        {(
          vendorPermission ||
          rawMaterialPermission ||
          menuPermission ||
          packagePermission ||
          purchasePermission ||
          transferPermission ||
          viewtransferPermission ||
          warehouseClosePermission
        ) && (
            <Accordion.Item
              as="li"
              eventKey="sidebar-settings-store"
              bsPrefix="nav-item"
            >
              <CustomToggle
                eventKey="sidebar-settings-store"
                onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <i className="icon">
                  <FaWarehouse size={30} />
                </i>
                <span className="item-name">Store</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-settings-store">
                <ul className="sub-nav">
                  <li className="nav-item">
                    <Link
                      className={`${location.pathname === "/app/store/report" ? "active" : ""
                        } nav-link`}
                      to="/app/store/report"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> R </i>
                      <span className="item-name">Report</span>
                    </Link>
                  </li>
                  {vendorPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/vendor" ? "active" : ""
                          } nav-link`}
                        to="/app/vendor"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> V </i>
                        <span className="item-name">Vendor</span>
                      </Link>
                    </li>
                  )}
                  {rawMaterialPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/raw_material" ? "active" : ""
                          } nav-link`}
                        to="/app/raw_material"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> R </i>
                        <span className="item-name">Raw Material</span>
                      </Link>
                    </li>
                  )}
                  {menuPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/menu" ? "active" : ""
                          } nav-link`}
                        to="/app/menu"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> R </i>
                        <span className="item-name">Menu</span>
                      </Link>
                    </li>
                  )}
                  {packagePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/package" ? "active" : ""
                          } nav-link`}
                        to="/app/package"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> P </i>
                        <span className="item-name">Package</span>
                      </Link>
                    </li>
                  )}
                  {purchasePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/purchase_entry" ? "active" : ""
                          } nav-link`}
                        to="/app/purchase_entry"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> P </i>
                        <span className="item-name">Purchase Entry</span>
                      </Link>
                    </li>
                  )}
                  {purchaseReturnPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/purchase_return" ? "active" : ""
                          } nav-link`}
                        to="/app/purchase_return"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> P </i>
                        <span className="item-name">Purchase Return</span>
                      </Link>
                    </li>
                  )}
                  {billMappingPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/bill_mapping" ? "active" : ""
                          } nav-link`}
                        to="/app/bill_mapping"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> B </i>
                        <span className="item-name">Bill Mapping</span>
                      </Link>
                    </li>
                  )}
                  {transferPermission && (
                    <>
                      <li className="nav-item">
                        <Link
                          className={`${location.pathname === "/app/operation/transfer/out/add" ? "active" : ""
                            } nav-link`}
                          to="/app/operation/transfer/out/add"
                        >
                          <i className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                            >
                              <g>
                                <circle
                                  cx="12"
                                  cy="12"
                                  r="8"
                                  fill="currentColor"
                                ></circle>
                              </g>
                            </svg>
                          </i>
                          <i className="sidenav-mini-icon"> T </i>
                          <span className="item-name">Transfer to Cafe</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`${location.pathname === "/app/operation/transfer/in/add" ? "active" : ""
                            } nav-link`}
                          to="/app/operation/transfer/in/add"
                        >
                          <i className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                            >
                              <g>
                                <circle
                                  cx="12"
                                  cy="12"
                                  r="8"
                                  fill="currentColor"
                                ></circle>
                              </g>
                            </svg>
                          </i>
                          <i className="sidenav-mini-icon"> D </i>
                          <span className="item-name">Damage Entry</span>
                        </Link>
                      </li>
                    </>
                  )}
                  {viewtransferPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/transfer" ? "active" : ""
                          } nav-link`}
                        to="/app/transfer"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> T </i>
                        <span className="item-name">Transfer</span>
                      </Link>
                    </li>
                  )}
                  {warehouseClosePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/warehouse_close" ? "active" : ""
                          } nav-link`}
                        to="/app/warehouse_close"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> W </i>
                        <span className="item-name">Warehouse Close</span>
                      </Link>
                    </li>
                  )}
                  {mistakeTicketPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/mistake_ticket" ? "active" : ""
                          } nav-link`}
                        to="/app/mistake_ticket"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> M </i>
                        <span className="item-name">Mistake Ticket</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>
          )}
        {(
          vendorPermission ||
          rawMaterialPermission ||
          menuPermission ||
          packagePermission ||
          purchasePermission ||
          transferPermission ||
          viewtransferPermission ||
          warehouseClosePermission
        ) && (
            <Accordion.Item
              as="li"
              eventKey="sidebar-settings-approvals"
              bsPrefix="nav-item"
            >
              <CustomToggle
                eventKey="sidebar-settings-approvals"
                onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <i className="icon">
                  <FcApproval size={30} />
                </i>
                <span className="item-name">Approvals</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-settings-approvals">
                <ul className="sub-nav">
                  {approvePurchaseEntryPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/purchase_entry_approval" ? "active" : ""
                          } nav-link`}
                        to="/app/purchase_entry_approval"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> E </i>
                        <span className="item-name">Purchase Entry</span>
                      </Link>
                    </li>
                  )}
                  {approvePurchaseReturnPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/purchase_return_approval" ? "active" : ""
                          } nav-link`}
                        to="/app/purchase_return_approval"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> R </i>
                        <span className="item-name">Purchase Return</span>
                      </Link>
                    </li>
                  )}
                  {approveRefundTicketPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/refund_ticket/approval" ? "active" : ""
                          } nav-link`}
                        to="/app/refund_ticket/approval"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> R </i>
                        <span className="item-name">Refund Ticket</span>
                      </Link>
                    </li>
                  )}
                  {approveCompTicketPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/comp_ticket/approval" ? "active" : ""
                          } nav-link`}
                        to="/app/comp_ticket/approval"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> C </i>
                        <span className="item-name">Complimentary Ticket</span>
                      </Link>
                    </li>
                  )}
                  {approveMovieSchedulePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/movie_schedule/approval" ? "active" : ""
                          } nav-link`}
                        to="/app/movie_schedule/approval"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> M </i>
                        <span className="item-name">Movie Schedule</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>
          )}
        {(operationDashboardPermission ||
          operationReportPermission ||
          morStaffPermission ||
          evenStaffPermission ||
          morStockPermission ||
          evenStockPermission ||
          morCoffeePermission ||
          evenCoffeePermission ||
          popcornPermission ||
          shortPunchPermission ||
          stockMistakePermission) && (
            <Accordion.Item
              as="li"
              eventKey="sidebar-settings-operations"
              bsPrefix="nav-item"
            >
              <CustomToggle
                eventKey="sidebar-settings-operations"
                onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <i className="icon">
                  <GrServices size={30} />
                </i>
                <span className="item-name">Operations</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-settings-operations">
                <ul className="sub-nav">
                  {operationDashboardPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/operation/dashboard" ? "active" : ""
                          } nav-link`}
                        to="/app/operation/dashboard"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> D </i>
                        <span className="item-name">Dashboard</span>
                      </Link>
                    </li>
                  )}
                  {operationReportPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/operation/report" ? "active" : ""
                          } nav-link`}
                        to="/app/operation/report"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> R </i>
                        <span className="item-name">Reports</span>
                      </Link>
                    </li>
                  )}
                  {morStaffPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/stsale_close/mor" ? "active" : ""
                          } nav-link`}
                        to="/app/stsale_close/mor"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> S </i>
                        <span className="item-name">Morning Staff Sales</span>
                      </Link>
                    </li>
                  )}
                  {evenStaffPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/stsale_close/even" ? "active" : ""
                          } nav-link`}
                        to="/app/stsale_close/even"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> S </i>
                        <span className="item-name">Evening Staff Sales</span>
                      </Link>
                    </li>
                  )}
                  {morStockPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/stock_close/mor" ? "active" : ""
                          } nav-link`}
                        to="/app/stock_close/mor"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> S </i>
                        <span className="item-name">Morning Stock Closing</span>
                      </Link>
                    </li>
                  )}
                  {evenStockPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/stock_close/even" ? "active" : ""
                          } nav-link`}
                        to="/app/stock_close/even"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> S </i>
                        <span className="item-name">Evening Stock Closing</span>
                      </Link>
                    </li>
                  )}
                  {morCoffeePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/coffee_close/mor" ? "active" : ""
                          } nav-link`}
                        to="/app/coffee_close/mor"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> C </i>
                        <span className="item-name">Morning Coffee Closing</span>
                      </Link>
                    </li>
                  )}
                  {evenCoffeePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/coffee_close/even" ? "active" : ""
                          } nav-link`}
                        to="/app/coffee_close/even"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> C </i>
                        <span className="item-name">Evening Coffee Closing</span>
                      </Link>
                    </li>
                  )}
                  {popcornPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/popcorn_yield" ? "active" : ""
                          } nav-link`}
                        to="/app/popcorn_yield"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> P </i>
                        <span className="item-name">Popcorn Yield</span>
                      </Link>
                    </li>
                  )}
                  {shortPunchPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/short_punch" ? "active" : ""
                          } nav-link`}
                        to="/app/short_punch"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> S </i>
                        <span className="item-name">Short Punch</span>
                      </Link>
                    </li>
                  )}
                  {stockMistakePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/stock_mistake" ? "active" : ""
                          } nav-link`}
                        to="/app/stock_mistake"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> S </i>
                        <span className="item-name">Stock Mistake</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>)}
        {(
          usernamePermission
        ) && (
            <Accordion.Item
              as="li"
              eventKey="sidebar-settings-information"
              bsPrefix="nav-item"
            >
              <CustomToggle
                eventKey="sidebar-settings-information"
                onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <i className="icon">
                  <HiOutlineDesktopComputer size={30} />
                </i>
                <span className="item-name">IT</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-settings-information">
                <ul className="sub-nav">
                  {usernamePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/employee/username" ? "active" : ""
                          } nav-link`}
                        to="/app/employee/username"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> E </i>
                        <span className="item-name">Employee Username</span>
                      </Link>
                    </li>
                  )}

                  <li className="nav-item">
                    <Link
                      className={`${location.pathname === "/app/information-technology/dashboard" ? "active" : ""
                        } nav-link`}
                      to="/app/information-technology/dashboard"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> D </i>
                      <span className="item-name">Dashboard</span>
                    </Link>
                  </li>
                  {refundTicketPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/refund_ticket" ? "active" : ""
                          } nav-link`}
                        to="/app/refund_ticket"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> R </i>
                        <span className="item-name">Refund Ticket</span>
                      </Link>
                    </li>
                  )}
                  {complimentaryTicketPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/comp_ticket" ? "active" : ""
                          } nav-link`}
                        to="/app/comp_ticket"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> C </i>
                        <span className="item-name">Complimentary Ticket</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>
          )}
        {(
          projectionPermission ||
          trailerPermission ||
          trikonPermission ||
          miscPermission
        )}
        <Accordion.Item
          as="li"
          eventKey="sidebar-settings-projection"
          bsPrefix="nav-item"
        >
          <CustomToggle
            eventKey="sidebar-settings-projection"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <i className="icon">
              <GiFilmProjector size={30} />
            </i>
            <span className="item-name">Projection</span>
            <i className="right-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="sidebar-settings-projection">
            <ul className="sub-nav">
              {projectionPermission && (
                <li className="nav-item">
                  <Link
                    className={`${location.pathname === "/app/projection/report" ? "active" : ""
                      } nav-link`}
                    to="/app/projection/report"
                  >
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <g>
                          <circle
                            cx="12"
                            cy="12"
                            r="8"
                            fill="currentColor"
                          ></circle>
                        </g>
                      </svg>
                    </i>
                    <i className="sidenav-mini-icon"> R</i>
                    <span className="item-name">Report</span>
                  </Link>
                </li>
              )}
              <li className="nav-item">
                <Link
                  className={`${location.pathname === "/app/projection/dashboard" ? "active" : ""
                    } nav-link`}
                  to="/app/projection/dashboard"
                >
                  <i className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> A </i>
                  <span className="item-name">Ad Management</span>
                </Link>
              </li>
              {/* {trailerPermission && (
                <li className="nav-item">
                  <Link
                    className={`${location.pathname === "/app/projection/trailer" ? "active" : ""
                      } nav-link`}
                    to="/app/projection/trailer"
                  >
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <g>
                          <circle
                            cx="12"
                            cy="12"
                            r="8"
                            fill="currentColor"
                          ></circle>
                        </g>
                      </svg>
                    </i>
                    <i className="sidenav-mini-icon"> T </i>
                    <span className="item-name">Trailer</span>
                  </Link>
                </li>
              )}
              {trikonPermission && (
                <li className="nav-item">
                  <Link
                    className={`${location.pathname === "/app/projection/trikon_ad" ? "active" : ""
                      } nav-link`}
                    to="/app/projection/trikon_ad"
                  >
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <g>
                          <circle
                            cx="12"
                            cy="12"
                            r="8"
                            fill="currentColor"
                          ></circle>
                        </g>
                      </svg>
                    </i>
                    <i className="sidenav-mini-icon"> T </i>
                    <span className="item-name">Trikon Ad</span>
                  </Link>
                </li>
              )}
              {miscPermission && (
                <li className="nav-item">
                  <Link
                    className={`${location.pathname === "/app/projection/misc_ad" ? "active" : ""
                      } nav-link`}
                    to="/app/projection/misc_ad"
                  >
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <g>
                          <circle
                            cx="12"
                            cy="12"
                            r="8"
                            fill="currentColor"
                          ></circle>
                        </g>
                      </svg>
                    </i>
                    <i className="sidenav-mini-icon"> M </i>
                    <span className="item-name">Misc Ad</span>
                  </Link>
                </li>
              )} */}
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default VerticalNav;
