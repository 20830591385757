import axiosClient from "../axios";
import axiosFile from "../axios-file";

class EmployeeDocsService {
    //inital data for attribute list
    employeeDocInitalData() {
        return axiosClient.get('/employees/docs/intial_data');
    }
    lists() {
        return axiosClient.get('/employees/docs/list');
    }
    //to get all data
    view(page, rowsPerPage) {
        return axiosClient.get(`/employees/docs?page=${page}&pp=${rowsPerPage}`);
    }
    //to store data
    store(values) {
        return axiosFile.post("/employees/docs", values);
    }
    //to update data
    update(uuid, values) {
        return axiosFile.put(`/employees/docs/${uuid}`, values);
    }
    // to simple search 
    search(url) {
        return axiosClient.get(`/employees/docs?${url}`);
    }
    //to search single data
    singleView(uuid) {
        return axiosClient.get(`/employees/docs/${uuid}`);
    }
    //to delete single data
    delete(uuid) {
        return axiosClient.delete("/employees/docs/" + uuid, { employee_id: uuid });
    }
    //to restore single data
    restore(uuid) {
        return axiosClient.delete(`/employees/docs/${uuid}/restore`);
    }
    //to prune single data
    prune(uuid) {
        return axiosClient.delete(`/employees/docs/${uuid}/prune`);
    }
    //to delete selected row data
    deleteAll(selectedRowsUid) {
        return axiosClient.post("/employees/docs/deleteall", { 'ids': selectedRowsUid });
    }
    //to restore selected row data
    restoreAll(selectedRowsUUID) {
        return axiosClient.post("/employees/docs/restoreall", { 'ids': selectedRowsUUID });
    }
    //to prune selected row data
    pruneAll(selectedRowsUUID) {
        return axiosClient.post("/employees/docs/pruneall", { 'ids': selectedRowsUUID });
    }
    //to get deleted data
    viewDeleted() {
        return axiosClient.get("/employees/docs");
    }
}
export default new EmployeeDocsService();