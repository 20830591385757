import axiosClient from "../axios";
import axiosFile from "../axios-file";

class PurchaseEntryDetailService {
    storeAll(values) {
        return axiosClient.post('/purchase_entries/details/store_all', values);
    }
    lists() {
        return axiosClient.get('/purchase_entries/details/list');
    }
    //to get all data
    view(page, rowsPerPage) {
        return axiosClient.get(`/purchase_entries/details?page=${page}&pp=${rowsPerPage}`);
    }
    //to store data
    store(values) {
        return axiosFile.post("/purchase_entries/details", values);
    }
    //to update data
    update(uuid, values) {
        return axiosClient.put(`/purchase_entries/details/${uuid}`, values);
    }
    // to simple search 
    search(url) {
        return axiosClient.get(`/purchase_entries/details?${url}`);
    }
    //to search single data
    singleView(uuid) {
        return axiosClient.get(`/purchase_entries/details/${uuid}`);
    }
    //to delete single data
    delete(uuid, warehouse_id) {
        return axiosClient.delete("/purchase_entries/details/" + uuid, {
            params: {
                warehouse_id: warehouse_id
            }
        });
    }
    //to restore single data
    restore(uuid) {
        return axiosClient.delete(`/purchase_entries/details/${uuid}/restore`);
    }
    //to prune single data
    prune(uuid) {
        return axiosClient.delete(`/purchase_entries/details/${uuid}/prune`);
    }
    //to delete selected row data
    deleteAll(selectedRowsUid) {
        return axiosClient.post("/purchase_entries/details/deleteall", { 'ids': selectedRowsUid });
    }
    //to restore selected row data
    restoreAll(selectedRowsUUID) {
        return axiosClient.post("/purchase_entries/details/restoreall", { 'ids': selectedRowsUUID });
    }
    //to prune selected row data
    pruneAll(selectedRowsUUID) {
        return axiosClient.post("/purchase_entries/details/pruneall", { 'ids': selectedRowsUUID });
    }
    //to get deleted data
    viewDeleted() {
        return axiosClient.get("/purchase_entries/details");
    }
}
export default new PurchaseEntryDetailService();