import React from 'react'
//layoutpages
import Default from '../layouts/dashboard/default'
import Simple from '../layouts/base/simple'

// authentication pages
import { Routes, Route } from 'react-router-dom'
import AddEmployee from '../views/employee/add-employee'
import TicketStatus from '../views/employee/ticket-status'

const IndexRouters = () => {
    return (
        <>
            <Routes>
                <Route exact path="/" element={<Simple />} />
                <Route exact path="/auth/*" element={<Simple />} />
                <Route exact path="/app/*" element={<Default />} />
                <Route exact path="/error/*" element={<Simple />} />

                {/* Routes for employee */}
                <Route exact path="/employee/registration" element={<AddEmployee />} />
                <Route exact path="/employee/tickets" element={<TicketStatus />} />
                <Route path="*" element={<Simple />} />
            </Routes>
        </>
    )
}

export default IndexRouters
