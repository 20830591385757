import React from "react";
import { Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const MiniCard = ({ name, href, icons }) => {
  return (
    <>
      <Link to={href}>
        <Card className="iq-product-custom-card position-relative">
          <Card.Body className="d-flex flex-column gap-3 justify-content-center align-items-center">
            <div className="m-auto iq-product-hover-img  d-inline-block p-4 bg-soft-primary rounded-pill d-flex align-items-center">
              {icons}
            </div>
            <Col>
              <div className="d-flex">
                <h6>{name}</h6>
              </div>
            </Col>
          </Card.Body>
        </Card>
      </Link>
    </>
  );
};

export default MiniCard;
