import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Form, Image, Row } from "react-bootstrap";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useStateContext } from '../../context/ContextProvider';
import purchaseReturnService from "../../services/purchase-return.service";
import { baseUrl } from "../../base-url";
import approvalService from "../../services/approval.service";

export default function ViewPurchaseReturnApproval() {
  const { setPageTitle, showToast, setIsLoading } = useStateContext();

  const navigate = useNavigate();
  const routeParams = useParams();

  const [data, setData] = useState();
  const [detailData, setDetailData] = useState();

  const displayError = (error) => {
    let errArr = [];
    errArr = Object.keys().map((key) => {
      return error[key].join(",");
    });
    errArr.map((column) =>
      showToast(column, "error")
    );
  }

  const [remarks, setRemarks] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [approval, setApproval] = useState('');

  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };

  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleApprovalChange = (e) => {
    setApproval(e.target.value)
  }

  const handleSubmit = () => {
    setIsLoading(true);
    if (remarks.trim() !== '' && selectedValue.trim() !== '' && approval.trim() !== '') {
      purchaseReturnService.approve({
        status_id: data.status_id,
        approval_id: approval,
        remarks: remarks,
        status: selectedValue
      }).then((response) => {
        showToast(`The status is updated to ${selectedValue}`, "success");
        navigate("/app/purchase_return_approval");
      }).catch((error) => {
        displayError(error.response.data.errors);
      })
    } else {
      setIsLoading(false);
      showToast("Please provide approval, remarks and status.", "error");
    }
  }

  const getData = () => {
    setIsLoading(true);
    purchaseReturnService
      .singleView(routeParams.id)
      .then((response) => {
        setData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        displayError(error.response.data.errors);
      });
  };

  const [approvalData, setApprovalData] = useState();
  const getAprovalData = () => {
    setIsLoading(true);
    const values = {
      "section_name": "Purchase Return"
    }
    approvalService.childrenList(values)
      .then((response) => {
        setIsLoading(true);
        setApprovalData(response.data);
      })
      .catch((error) => {
        setIsLoading(true);
        displayError(error.response.data.errors);
      })
  }

  useEffect(() => {
    getAprovalData();
    setPageTitle("Purchase Returns");
    getData();
  }, [setPageTitle]);
  return (
    <Container>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Form.Select value={approval} onChange={handleApprovalChange}>
                <option value="">Select the approval</option>
                {approvalData ? (
                  <>
                    {approvalData.map((list) => (
                      <option key={list.id} value={list.id}>
                        {list.text}
                      </option>
                    ))}
                  </>
                ) : null}
              </Form.Select>
            </Col>
            <Col>
              <Form.Control type="text" placeholder="Remarks" value={remarks} onChange={handleRemarksChange} />
            </Col>
            <Col>
              <Form.Select value={selectedValue} onChange={handleSelectChange}>
                <option defaultValue="">Select the status</option>
                <option value="Approved">Approved</option>
                <option value="Pending">Pending</option>
                <option value="Not Approved">Not Approved</option>
              </Form.Select>
            </Col>
            <Col>
              <Button variant="primary" onClick={handleSubmit}>Submit</Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          {data ? (
            <Row className="mb-3">
              <h5> About Purchase Return</h5>
              <hr className="my-3" />
              <Col md="6" className="mb-3">
                <div className="mt-2">
                  <h6 className="mb-1">Invoice Number:</h6>
                  <p>{data.purchase_entry ? data.purchase_entry.invoice_no : ""}</p>
                </div>
              </Col>
              <Col md="6" className="mb-3">
                <div className="mt-2">
                  <h6 className="mb-1">Challan Number:</h6>
                  <p>{data.purchase_entry ? data.purchase_entry.challan_no : ""}</p>
                </div>
              </Col>
              <Col md="6" className="mb-3">
                <div className="mt-2">
                  <h6 className="mb-1">Invoice Date:</h6>
                  <p>{data.purchase_entry ? data.purchase_entry.invoice_date : ""}</p>
                </div>
              </Col>
              <Col md="6" className="mb-3">
                <div className="mt-2">
                  <h6 className="mb-1">Vendor Name:</h6>
                  <p>{data.purchase_entry ? data.purchase_entry.vendor_name : ""}</p>
                </div>
              </Col>
              <Col md="6" className="mb-3">
                <div className="mt-2">
                  <h6 className="mb-1">Raw Material Name:</h6>
                  <p>{data.item ? data.item.raw_material_name : ""}</p>
                </div>
              </Col>
              <Col md="6" className="mb-3">
                <div className="mt-2">
                  <h6 className="mb-1">Batch ID:</h6>
                  <p>{data.batch_id}</p>
                </div>
              </Col>
              <Col md="6" className="mb-3">
                <div className="mt-2">
                  <h6 className="mb-1">Amount:</h6>
                  <p>{data.amount}</p>
                </div>
              </Col>
              <Col md="6" className="mb-3">
                <div className="mt-2">
                  <h6 className="mb-1">Entry Date:</h6>
                  <p>{data.entry_date}</p>
                </div>
              </Col>
              <Col md="12" className="mb-3">
                <div className="mt-2">
                  <h6 className="mb-1">Purchase Return Status:</h6>
                  {data.status.map((statusItem, index) => (
                    <div key={index} className="mb-2">
                      <strong>{statusItem.approval_name}:</strong> {statusItem.status}
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          ) : null}
        </Card.Body>
      </Card>
    </Container>
  );
}
