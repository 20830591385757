import axios from "axios";
import { baseUrl } from "./base-url";
import { useNavigate } from "react-router-dom";

const axiosFile = axios.create({
    baseURL: `${baseUrl}/api`,
    // baseURL: 'http://localhost:8000/api',
    //this code is updated
    mode: 'no-cors',
    // headers: {
    //     // "Content-type": "application/json",
    //     'Content-type': 'multipart/form-data',
    //     "Accept": "application/json"
    // }
});

axiosFile.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    return config
});

axiosFile.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response && error.response.status === 401) {
        localStorage.removeItem('token')
        const navigate = useNavigate();
        navigate('/');
        return error;
    }
    throw error;
})

export default axiosFile;