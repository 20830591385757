import axiosClient from "../axios";

class StockCloseDetailService {
    //function to get shift summary
    shift_summary(values) {
        return axiosClient.post('/mor_closes/details/shift_summary', values);
    }
    monthly_summary(values) {
        return axiosClient.post('/mor_closes/details/monthly_summary', values);
    }
    control_summary(values) {
        return axiosClient.post('/mor_closes/details/control_summary', values);
    }
    rec_summary(values) {
        return axiosClient.post('/mor_closes/details/rec_summary', values);
    }
    day_summary(values) {
        return axiosClient.post('/mor_closes/details/day_summary', values);
    }
    user_wise_sales(values) {
        return axiosClient.post('/mor_closes/details/user_wise_sales', values);
    }
    popcorn_yield_summary(url) {
        return axiosClient.post(`/mor_closes/details/popcorn_summary?${url}`);
    }
    lists() {
        return axiosClient.get('/mor_closes/details/list');
    }
    //to get all data
    view(page, rowsPerPage, type) {
        return axiosClient.get(`/${type}_closes/details?page=${page}&pp=${rowsPerPage}`);
    }
    //to store data
    store(values, type) {
        return axiosClient.post(`/${type}_closes/details`, values);
    }
    //to update data
    update(uuid, values, type) {
        return axiosClient.put(`/${type}_closes/details/${uuid}`, values);
    }
    // to simple search 
    search(url, type) {
        return axiosClient.get(`/${type}_closes/details?${url}`);
    }
    //to search single data
    singleView(uuid, type) {
        return axiosClient.get(`/${type}_closes/details/${uuid}`);
    }
    //to delete single data
    delete(uuid, type) {
        return axiosClient.delete(`/${type}_closes/details/` + uuid, { detail_id: uuid });
    }
    //to restore single data
    restore(uuid) {
        return axiosClient.delete(`/mor_closes/details/${uuid}/restore`);
    }
    //to prune single data
    prune(uuid) {
        return axiosClient.delete(`/mor_closes/details/${uuid}/prune`);
    }
    //to delete selected row data
    deleteAll(selectedRowsUid) {
        return axiosClient.post("/mor_closes/details/deleteall", { 'ids': selectedRowsUid });
    }
    //to restore selected row data
    restoreAll(selectedRowsUUID) {
        return axiosClient.post("/mor_closes/details/restoreall", { 'ids': selectedRowsUUID });
    }
    //to prune selected row data
    pruneAll(selectedRowsUUID) {
        return axiosClient.post("/mor_closes/details/pruneall", { 'ids': selectedRowsUUID });
    }
    //to get deleted data
    viewDeleted() {
        return axiosClient.get("/mor_closes/details");
    }
}
export default new StockCloseDetailService();