import { useFormik } from "formik";
import React, { useState, useEffect, Fragment } from "react";
import {
    Button,
    Col,
    Container,
    Form,
    Offcanvas,
    Row,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { AiFillDelete, AiFillGooglePlusCircle, AiFillPlusCircle } from "react-icons/ai";
import DataTable from "react-data-table-component";
import { HiMiniViewfinderCircle } from "react-icons/hi2";
import { useStateContext } from "../../context/ContextProvider";
import stsaleCloseDetailService from "../../services/stsale-close-detail.service";
import ViewStsaleDetail from "./stsale-close-detail/view-stsale-detail";
import { usePermissionContext } from "../../context/PremissionContext";
import TableIcons from "../../components/TableIcons";
import employeeService from "../../services/employee.service";
import attributeService from "../../services/attribute.service";
import stsaleCloseService from "../../services/stsale-close.service";
import Select from 'react-select';

const OpStsaleCloseDetail = ({ close_data }) => {
    const { id, type } = useParams();
    const navigate = useNavigate();
    const { permissions } = usePermissionContext();
    const {
        dtColorMode,
        setIsLoading,
        setPageTitle,
        showToast,
        setRefereshData,
        refereshData,
    } = useStateContext();

    const permissionNames = {
        mor: {
            create: "Create Mor Stsale Detail",
            update: "Update Mor Stsale Detail",
            view: "View Mor Stsale Detail",
            delete: "Delete Mor Stsale Detail",
            restore: "Restore Mor Stsale Detail",
            purge: "Purge Mor Stsale Detail",
        },
        even: {
            create: "Create Even Stsale Detail",
            update: "Update Even Stsale Detail",
            view: "View Even Stsale Detail",
            delete: "Delete Even Stsale Detail",
            restore: "Restore Even Stsale Detail",
            purge: "Purge Even Stsale Detail",
        },
    };

    const getPermissions = (type) => {
        return {
            create: permissions.some(
                (permission) => permission.name === permissionNames[type].create
            ),
            update: permissions.some(
                (permission) => permission.name === permissionNames[type].update
            ),
            view: permissions.some(
                (permission) => permission.name === permissionNames[type].view
            ),
            delete: permissions.some(
                (permission) => permission.name === permissionNames[type].delete
            ),
            restore: permissions.some(
                (permission) => permission.name === permissionNames[type].restore
            ),
            purge: permissions.some(
                (permission) => permission.name === permissionNames[type].purge
            ),
        };
    };

    const {
        create: createPermission,
        update: updatePermission,
        view: viewPermission,
        delete: deletePermission,
        restore: restorePermission,
        purge: purgePermission,
    } = getPermissions(type);

    const displayError = (error) => {
        const errArr = Object.keys(error).map((key) => error[key].join(","));
        errArr.forEach((column) => showToast(column, "error"));
    };

    const [data, setData] = useState([]);

    const [employeeList, setEmployeeList] = useState([]);
    const getEmployeeList = () => {
        employeeService
            .assoicateLists()
            .then((response) => {
                setEmployeeList(response.data.data);
            })
            .catch((error) => {
                displayError(error.response.data.errors);
            });
    };

    const [jobLocation, setJobLocation] = useState([]);
    const getJobLocation = () => {
        attributeService
            .childrenList("Job Location")
            .then((response) => {
                setJobLocation(response.data);
            })
            .catch((error) => {
                displayError(error.response.data.errors);
            });
    };

    useEffect(() => {
        getEmployeeList();
        getJobLocation();
    }, [refereshData]);

    const [forms, setForms] = useState([{
        staff_id: '',
        job_location: '',
        cash: '',
        e_card: '',
        qr_pay: '',
        fone_pe: '',
        online: '',
        comp: '',
        refund: '',
        total_amount: ''
    }]);

    const addForm = () => {
        setForms([...forms, {
            staff_id: '',
            job_location: '',
            cash: '',
            e_card: '',
            qr_pay: '',
            fone_pe: '',
            online: '',
            comp: '',
            refund: '',
            total_amount: ''
        }]);
    };

    const removeForm = (index) => {
        setForms(forms.filter((_, i) => i !== index));
    };

    const handleStaffChange = (index, selectedValue) => {
        const newForms = [...forms];
        // Update the selected value
        newForms[index] = {
            ...newForms[index],
            staff_id: selectedValue ? selectedValue.value : null
        };

        // Calculate total_amount
        const updatedForm = {
            ...newForms[index],
            total_amount: calculateTotal(newForms[index])
        };
        newForms[index] = updatedForm;

        // Update state
        setForms(newForms);
    };

    const handleChange = (index, e) => {
        const { name, value } = e.target;
        const newForms = [...forms];
        newForms[index] = { ...newForms[index], [name]: value };
        // Calculate total_amount
        const updatedForm = { ...newForms[index], total_amount: calculateTotal(newForms[index]) };
        newForms[index] = updatedForm;
        setForms(newForms);
    };

    const calculateTotal = (form) => {
        return ['cash', 'e_card', 'qr_pay', 'fone_pe', 'online', 'comp', 'refund'].reduce((acc, key) => {
            return acc + (parseFloat(form[key]) || 0);
        }, 0);
    };

    const handleBlur = (index, e) => {
        // Handle onBlur if necessary
    };

    const handleSubmitAll = () => {
        setIsLoading(true);
        if (forms.length > 0) {
            stsaleCloseService.storeAll({
                main: close_data,
                detail: forms
            }, type).then((response) => {
                setIsLoading(false);
                navigate('/app/operation/dashboard');
            }).catch((error) => {
                setIsLoading(false);
                displayError(error.response.data.errors);
            })
        } else {
            setIsLoading(false);
            showToast("No data to submit", "warning");
        }
    };

    const staffOptions = employeeList?.map((staff) => ({
        value: staff.id,
        label: staff.text,
    })) || [];

    return (
        <Fragment>
            <div>
                {forms.map((form, index) => (
                    <Form key={index} noValidate>
                        <Row>
                            <Col md="6">
                                <Form.Label htmlFor={`staff_id_${index}`} className="h6">Staff Name</Form.Label>
                                <Select
                                    id={`staff_id_${index}`}
                                    name="staff_id"
                                    options={staffOptions}
                                    value={staffOptions.find(
                                        (option) => option.value === form.staff_id
                                    )}
                                    onChange={(selectedValue) => handleStaffChange(index, selectedValue)}
                                    onBlur={(e) => handleBlur(index, e)}
                                />
                                {/* <Form.Select
                                    className="form-control"
                                    id={`staff_id_${index}`}
                                    name="staff_id"
                                    value={form.staff_id}
                                    onChange={(e) => handleChange(index, e)}
                                    onBlur={(e) => handleBlur(index, e)}
                                >
                                    <option value="">Select Specific Staff</option>
                                    {employeeList.map((employee) => (
                                        <option key={employee.id} value={employee.id}>{employee.text}</option>
                                    ))}
                                </Form.Select> */}
                            </Col>
                            <Col md="6">
                                <Form.Label htmlFor={`job_location_${index}`} className="h6">Job Location</Form.Label>
                                <Form.Select
                                    className="form-control"
                                    id={`job_location_${index}`}
                                    name="job_location"
                                    value={form.job_location}
                                    onChange={(e) => handleChange(index, e)}
                                    onBlur={(e) => handleBlur(index, e)}
                                >
                                    <option value="">Select Specific Job Location</option>
                                    {jobLocation.map((location) => (
                                        <option key={location.id} value={location.text}>{location.text}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col md="2">
                                <Form.Label htmlFor={`cash_${index}`} className="h6">Cash</Form.Label>
                                <Form.Control
                                    type="number"
                                    id={`cash_${index}`}
                                    name="cash"
                                    value={form.cash}
                                    onChange={(e) => handleChange(index, e)}
                                    onBlur={(e) => handleBlur(index, e)}
                                />
                            </Col>
                            <Col md="2">
                                <Form.Label htmlFor={`e_card_${index}`} className="h6">E-Card</Form.Label>
                                <Form.Control
                                    type="number"
                                    id={`e_card_${index}`}
                                    name="e_card"
                                    value={form.e_card}
                                    onChange={(e) => handleChange(index, e)}
                                    onBlur={(e) => handleBlur(index, e)}
                                />
                            </Col>
                            <Col md="2">
                                <Form.Label htmlFor={`qr_pay_${index}`} className="h6">QR Pay</Form.Label>
                                <Form.Control
                                    type="number"
                                    id={`qr_pay_${index}`}
                                    name="qr_pay"
                                    value={form.qr_pay}
                                    onChange={(e) => handleChange(index, e)}
                                    onBlur={(e) => handleBlur(index, e)}
                                />
                            </Col>
                            <Col md="2">
                                <Form.Label htmlFor={`fone_pe_${index}`} className="h6">FonePay</Form.Label>
                                <Form.Control
                                    type="number"
                                    id={`fone_pe_${index}`}
                                    name="fone_pe"
                                    value={form.fone_pe}
                                    onChange={(e) => handleChange(index, e)}
                                    onBlur={(e) => handleBlur(index, e)}
                                />
                            </Col>
                            <Col md="2">
                                <Form.Label htmlFor={`online_${index}`} className="h6">Online</Form.Label>
                                <Form.Control
                                    type="number"
                                    id={`online_${index}`}
                                    name="online"
                                    value={form.online}
                                    onChange={(e) => handleChange(index, e)}
                                    onBlur={(e) => handleBlur(index, e)}
                                />
                            </Col>
                            <Col md="2">
                                <Form.Label htmlFor={`comp_${index}`} className="h6">Complimentary</Form.Label>
                                <Form.Control
                                    type="number"
                                    id={`comp_${index}`}
                                    name="comp"
                                    value={form.comp}
                                    onChange={(e) => handleChange(index, e)}
                                    onBlur={(e) => handleBlur(index, e)}
                                />
                            </Col>
                            <Col md="2">
                                <Form.Label htmlFor={`refund_${index}`} className="h6">Refund</Form.Label>
                                <Form.Control
                                    type="number"
                                    id={`refund_${index}`}
                                    name="refund"
                                    value={form.refund}
                                    onChange={(e) => handleChange(index, e)}
                                    onBlur={(e) => handleBlur(index, e)}
                                />
                            </Col>
                            <Col md="2">
                                <Form.Label htmlFor={`total_amount_${index}`} className="h6">Total Amount</Form.Label>
                                <Form.Control
                                    type="number"
                                    id={`total_amount_${index}`}
                                    name="total_amount"
                                    value={form.total_amount}
                                    readOnly
                                />
                            </Col>
                            <Col className="d-flex justify-content-end p-2 m-3 gap-2">
                                <a className="btn btn-sm btn-outline-danger d-flex p-2"
                                    onClick={() => removeForm(index)}
                                >
                                    <AiFillDelete style={{ fontSize: "1.3rem" }} />
                                </a>
                                <Button variant="btn btn-outline-primary" onClick={addForm}>
                                    <AiFillPlusCircle style={{ fontSize: "1.3rem" }} />
                                    Add New
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                ))}
                <div className="mt-3 text-end">
                    <Button onClick={handleSubmitAll}>Submit All Data</Button>
                </div>
            </div>
        </Fragment>
    );
};

export default OpStsaleCloseDetail;
