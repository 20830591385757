import axiosClient from "../axios";

class CoffeeCloseService {
    storeAll(values, type) {
        return axiosClient.post(`/${type}_coffees/store_all`, values);
    }
    lists() {
        return axiosClient.get('/mor_coffees/list');
    }
    //to get all data
    view(page, rowsPerPage, type) {
        return axiosClient.get(`/${type}_coffees?page=${page}&pp=${rowsPerPage}`);
    }
    //to store data
    store(values, type) {
        return axiosClient.post(`/${type}_coffees`, values);
    }
    //to update data
    update(uuid, values, type) {
        return axiosClient.put(`/${type}_coffees/${uuid}`, values);
    }
    // to simple search 
    search(url, type) {
        return axiosClient.get(`/${type}_coffees?${url}`);
    }
    //to search single data
    singleView(uuid, type) {
        return axiosClient.get(`/${type}_coffees/${uuid}`);
    }
    //to delete single data
    delete(uuid, type) {
        return axiosClient.delete(`/${type}_coffees/` + uuid, { close_id: uuid });
    }
    //to restore single data
    restore(uuid, type) {
        return axiosClient.delete(`/${type}_coffees/${uuid}/restore`);
    }
    //to prune single data
    prune(uuid, type) {
        return axiosClient.delete(`/${type}_coffees/${uuid}/prune`);
    }
    //to delete selected row data
    deleteAll(selectedRowsUid, type) {
        return axiosClient.post(`/${type}_coffees/deleteall`, { 'ids': selectedRowsUid });
    }
    //to restore selected row data
    restoreAll(selectedRowsUUID, type) {
        return axiosClient.post(`/${type}_coffees/restoreall`, { 'ids': selectedRowsUUID });
    }
    //to prune selected row data
    pruneAll(selectedRowsUUID, type) {
        return axiosClient.post(`/${type}_coffees/pruneall`, { 'ids': selectedRowsUUID });
    }
    //to get deleted data
    viewDeleted(type) {
        return axiosClient.get(`/${type}_coffees`);
    }
}
export default new CoffeeCloseService();