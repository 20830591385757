import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormCheck,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../../context/ContextProvider";
import rawMaterialService from "../../../services/raw-material.service";
import stsaleCloseDetailService from "../../../services/stsale-close-detail.service";
import stsaleCloseDetailUpdateSchema from "./stsale-close-detail-update-schema";
import stsaleCloseDetailStoreSchema from "./stsale-close-detail-store-schema";
import employeeService from "../../../services/employee.service";
import attributeService from "../../../services/attribute.service";

const AddStsaleCloseDetail = ({ setShowAdd, showAdd, id, close_id, type }) => {
  const navigate = useNavigate();

  const {
    setIsLoading,
    setPageTitle,
    showToast,
    setRefereshData,
    refereshData,
  } = useStateContext();

  const data = {
    staff_id: "",
    job_location: "",
    cash: "0",
    e_card: "0",
    qr_pay: "0",
    fone_pe: "0",
    online: "0",
    comp: "0",
    refund: "0",
    total_amount: "0",
    status: "Active",
    created_by: "",
    updated_by: "",
  };

  const displayError = (error) => {
    let errArr = [];
    errArr = Object.keys(error).map((key) => {
      return error[key].join(",");
    });
    errArr.map((column) => showToast(column, "error"));
  };

  const store = (values) => {
    setIsLoading(true);
    let updatedValues = {
      ...values,
      close_id: close_id,
    };
    stsaleCloseDetailService
      .store(updatedValues, type)
      .then((response) => {
        setIsLoading(false);
        setShowAdd(!showAdd);
        navigate(`/app/stsale_close/${type}/edit/${close_id}`);
        setRefereshData(true);
        showToast(`New Stsale Close Detail added.`, "success");
      })
      .catch((error) => {
        setIsLoading(false);
        displayError(error.response.data.errors);
      });
  };

  const update = (values) => {
    setIsLoading(true);
    stsaleCloseDetailService
      .update(id, values, type)
      .then((response) => {
        setIsLoading(false);
        setShowAdd(false);
        navigate(`/app/stsale_close/${type}/edit/${close_id}`);
        setRefereshData(true);
        showToast(`Stsale Close Detail updated.`, "success");
      })
      .catch((error) => {
        setIsLoading(false);
        displayError(error.response.data.errors);
      });
  };

  const submitForm = (values) => {
    id === undefined || id === "0" ? store(values) : update(values);
  };

  const [itemList, setItemList] = useState();
  const getItemList = () => {
    rawMaterialService
      .lists()
      .then((response) => {
        setItemList(response.data.data);
      })
      .catch((error) => {
        displayError(error.response.data.errors);
      });
  };

  const [employeeList, setEmployeeList] = useState();

  const getEmployeeList = () => {
    employeeService
      .lists()
      .then((response) => {
        setEmployeeList(response.data.data);
      })
      .catch((error) => {
        displayError(error.response.data.errors);
      });
  };

  const [jobLocation, setJobLocation] = useState();

  const getJobLocation = () => {
    attributeService
      .childrenList("Job Location")
      .then((response) => {
        setJobLocation(response.data);
      })
      .catch((error) => {
        displayError(error.response.data.errors);
      });
  };
  const setInitialData = () => {
    if (id !== undefined) {
      setIsLoading(true);
      stsaleCloseDetailService
        .singleView(id, type)
        .then((response) => {
          if (response.status === 200) {
            formik.setValues(response.data.data);
          }
        })
        .catch((error) => {
          showToast(error.response.data.message, "error");
          navigate("/app/stsale_close");
        });
      setIsLoading(false);
    } else {
      formik.setValues(data);
    }
  };

  useEffect(() => {
    getEmployeeList();
    getJobLocation();
    setPageTitle("Staff Closes");
    setInitialData();
  }, [setPageTitle, id]);

  const formik = useFormik({
    initialValues: data,
    validationSchema:
      id !== undefined ? stsaleCloseDetailUpdateSchema : stsaleCloseDetailStoreSchema,
    onSubmit: submitForm,
  });

  useEffect(() => {
    const calculateTotalAmount = () => {
      const { cash, e_card, qr_pay, fone_pe, online, comp, refund } = formik.values;
      const total =
        parseFloat(cash) +
        parseFloat(e_card) +
        parseFloat(qr_pay) +
        parseFloat(fone_pe) +
        parseFloat(online) +
        parseFloat(comp) +
        parseFloat(refund);
      formik.setFieldValue("total_amount", total.toFixed(2));
    };

    calculateTotalAmount();
  }, [
    formik.values.cash,
    formik.values.e_card,
    formik.values.qr_pay,
    formik.values.fone_pe,
    formik.values.online,
    formik.values.comp,
    formik.values.refund,
  ]);

  return (
    <Container>
      <Offcanvas
        show={showAdd}
        onHide={() => setShowAdd(false)}
        placement="end"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <Offcanvas.Header class="offcanvas-header">
          <Offcanvas.Title id="offcanvasRightLabel">
            <h4 className="card-title">
              {id ? "Edit Staff Close Detail" : "Add Staff Close Detail"}
            </h4>
          </Offcanvas.Title>
          <button
            type="button"
            class="btn-close"
            onClick={() => {
              setShowAdd(false);
            }}
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Row className="mb-3">
              <h5>Basic Information</h5>
              <hr className="my-3" />
              <Col md="12">
                <Form.Label htmlFor="validationCustom02" className="h6">
                  Staff Name
                </Form.Label>
                <Form.Select
                  className="form-control"
                  id="staff_id"
                  name="staff_id"
                  value={formik.values.staff_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.staff_id && !!formik.errors.staff_id}
                >
                  <option value="">Select Specific Staff</option>
                  {employeeList ? (
                    <>
                      {employeeList.map((employee) => (
                        <option key={employee.id} value={employee.id}>
                          {employee.text}
                        </option>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.staff_id}
                </Form.Control.Feedback>
              </Col>
              <Col md="12">
                <Form.Label htmlFor="validationCustom02" className="h6">
                  Job Location
                </Form.Label>
                <Form.Select
                  className="form-control"
                  id="job_location"
                  name="job_location"
                  value={formik.values.job_location}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.job_location && !!formik.errors.job_location
                  }
                >
                  <option value="">Select Specific Job Location</option>
                  {jobLocation ? (
                    <>
                      {jobLocation.map((jobs) => (
                        <option key={jobs.id} value={jobs.text}>
                          {jobs.text}
                        </option>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.job_location}
                </Form.Control.Feedback>
              </Col>
              <Col md="12">
                <Form.Label htmlFor="validationCustom01" className="h6">
                  Cash
                </Form.Label>
                <Form.Control
                  type="number"
                  name="cash"
                  value={formik.values.cash}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.cash && !!formik.errors.cash}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.cash}
                </Form.Control.Feedback>
              </Col>
              <Col md="12">
                <Form.Label htmlFor="validationCustom01" className="h6">
                  E-Card
                </Form.Label>
                <Form.Control
                  type="number"
                  name="e_card"
                  value={formik.values.e_card}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.e_card && !!formik.errors.e_card}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.e_card}
                </Form.Control.Feedback>
              </Col>
              <Col md="12">
                <Form.Label htmlFor="validationCustom01" className="h6">
                  QR Pay
                </Form.Label>
                <Form.Control
                  type="number"
                  name="qr_pay"
                  value={formik.values.qr_pay}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.qr_pay && !!formik.errors.qr_pay}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.qr_pay}
                </Form.Control.Feedback>
              </Col>
              <Col md="12">
                <Form.Label htmlFor="validationCustom01" className="h6">
                  FonePay
                </Form.Label>
                <Form.Control
                  type="number"
                  name="fone_pe"
                  value={formik.values.fone_pe}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.fone_pe && !!formik.errors.fone_pe}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.fone_pe}
                </Form.Control.Feedback>
              </Col>
              <Col md="12">
                <Form.Label htmlFor="validationCustom01" className="h6">
                  Online
                </Form.Label>
                <Form.Control
                  type="number"
                  name="online"
                  value={formik.values.online}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.online && !!formik.errors.online}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.online}
                </Form.Control.Feedback>
              </Col>
              <Col md="12">
                <Form.Label htmlFor="validationCustom01" className="h6">
                  Complimentary
                </Form.Label>
                <Form.Control
                  type="number"
                  name="comp"
                  value={formik.values.comp}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.comp && !!formik.errors.comp}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.comp}
                </Form.Control.Feedback>
              </Col>
              <Col md="12">
                <Form.Label htmlFor="validationCustom01" className="h6">
                  Refund
                </Form.Label>
                <Form.Control
                  type="number"
                  name="refund"
                  value={formik.values.refund}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.refund && !!formik.errors.refund}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.refund}
                </Form.Control.Feedback>
              </Col>
              <Col md="12">
                <Form.Label htmlFor="validationCustom01" className="h6">
                  Total Amount
                </Form.Label>
                <Form.Control
                  type="number"
                  name="total_amount"
                  value={formik.values.total_amount}
                  readOnly
                />
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>
        <Button className='m-3 mb-0' variant="outline-secondary" type="submit" onClick={formik.handleSubmit}>
          {id !== undefined ? "Update" : "Save"}
        </Button>
        <Button className='m-3 mb-0' variant="outline-warning" onClick={setInitialData}>
          Reset Data
        </Button>
        <Button
          className='m-3 mb-0' variant="outline-secondary"
          onClick={() => {
            setShowAdd(false);
          }}
        >
          Cancel
        </Button>
      </Offcanvas>
    </Container>
  );
};

export default AddStsaleCloseDetail;
