import * as Yup from 'yup';
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];
const MAX_FILE_SIZE = 512 * 1024; // 255KB

const fileValidationSchema = Yup.mixed()
    .required('This field is required')
    .test(
        'fileSize',
        'The file must not be greater than 1MB kilobytes.',
        value => value && value.size <= MAX_FILE_SIZE
    )
    .test(
        'fileFormat',
        'Unsupported file format',
        value => value && SUPPORTED_FORMATS.includes(value.type)
    );

const purchaseEntryStoreSchema = Yup.object({
    warehouse_id: Yup.string().required("Warehouse Name is required"),
    invoice_no: Yup.string().nullable(),
    challan_no: Yup.string().required('Challan Number is required'),
    invoice_date: Yup.date().required('Invoice Date is required'),
    vendor_id: Yup.string().required('Vendor Name is required'),
    bill_file: fileValidationSchema,
});

export default purchaseEntryStoreSchema;