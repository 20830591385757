import axiosClient from "../axios";

class ImsReportService {
    ims() {
        return axiosClient.get('/ims_reports/ims');
    }
    purchase_filter_initial() {
        return axiosClient.get('/ims_reports/purchase_filtes/initials');
    }
    purchase_filter(values) {
        return axiosClient.post('/ims_reports/purchase_filters', values);
    }
}
export default new ImsReportService();