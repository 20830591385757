import { useContext } from "react";
import { useState } from "react";
import { createContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { ToastOptions } from "../components/base/ToastOptions";
import Loading from "../components/base/Loading";

const StateContext = createContext({
    pageTitle: 'Dashboard',
    currentUser: {},
    userToken: {},
    toast: {
        message: null,
        type: null,
        options: {},
        show: false,
    },
    dtColorMode: sessionStorage.getItem('color-mode'), // For datatable color mode
    setCurrentUser: () => { },
    setUserToken: () => { },
    setPageTitle: ()=>{ },
    setDtColorMode: ()=>{ },
});



export const ContextProvider = ({ children }) => {
    const [pageTitle, setPageTitle] = useState('Dashboard');
    const [currentUser, setCurrentUser] = useState({});
    const [userToken, _setUserToken] = useState(localStorage.getItem('token') || '');
    const [toastData, setToastData] = useState({ message: '', options: {}, show: false });
    const [isLoading, setIsLoading] = useState(false);
    const [refereshData,setRefereshData]=useState(false);
    const [dtColorMode, setDtColorMode]=useState(sessionStorage.getItem('color-mode')||'');
    const setUserToken = (token) => {
        if (token) {
            localStorage.setItem('token', token);
        } else {
            localStorage.removeItem('token');
        }
        _setUserToken(token);
    }

    const showToast = (message, type) => {
        if (type === 'warn') {
            toast.warn(message, ToastOptions);
        }
        if (type === 'success') {
            toast.success(message, ToastOptions);
        }
        if (type === 'error') {
            toast.error(message, ToastOptions);
        }
        if (type === 'default') {
            toast(message, ToastOptions);
        }
        if (type === 'info') {
            toast.info(message, ToastOptions);
        }
    }

    return (
        <StateContext.Provider value={{
            currentUser,
            setCurrentUser,
            userToken,
            setUserToken,
            toast: toastData,
            setToastData,
            showToast,
            isLoading,
            setIsLoading,
            refereshData,
            setRefereshData,
            pageTitle,
            setPageTitle,
            dtColorMode,
            setDtColorMode,

        }}>
            {/* <ToastContainer /> */}
            <Loading />
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = () => useContext(StateContext);