import * as Yup from 'yup';

// Get today's date and yesterday's date in YYYY-MM-DD format
// const today = new Date().toISOString().split('T')[0];
// const yesterday = new Date(Date.now() - 86400000).toISOString().split('T')[0];
const now = new Date();
const kathmanduTimeOptions = { timeZone: 'Asia/Kathmandu', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
const todayKathmandu = new Date(now.toLocaleString('en-US', { timeZone: 'Asia/Kathmandu' }));
const today = todayKathmandu.toISOString().split('T')[0];

const yesterdayKathmandu = new Date(todayKathmandu);
yesterdayKathmandu.setDate(todayKathmandu.getDate() - 1);
const yesterday = yesterdayKathmandu.toISOString().split('T')[0];


const shiftCloseStoreSchema = Yup.object({
    shift: Yup.string().required('Shift is required'),
    entry_date: Yup.date()
        .required('Entry Date is required')
        .min(yesterday, `Entry Date cannot be before ${yesterday}`)
        .max(today, `Entry Date cannot be after ${today}`),
    status: Yup.string().required('ShiftClose Status is required.'),
});

export default shiftCloseStoreSchema;
