import * as Yup from 'yup';

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];
const MAX_FILE_SIZE = 255 * 1024; // 255KB

const fileValidationSchema = Yup.mixed()
    .test(
        'fileSize',
        'The file must not be greater than 255 kilobytes.',
        value => !value || (value && value.size <= MAX_FILE_SIZE)
    )
    .test(
        'fileFormat',
        'Unsupported file format',
        value => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    )
    .nullable();

const employeeDocsStoreSchema = Yup.object({
    passport_photo: fileValidationSchema
        .required('Passport Photo is required'),
    highest_education: Yup.string().required('Highest Education is required'),
    completed_year: Yup.date().required('Completed Year is required'),
    education_file_path: fileValidationSchema
        .required('Education file is required'),
    bank_name: Yup.string().required('Bank Name is required'),
    account_number: Yup.string().required('Account Number is required'),
    lincense_number: Yup.string(),
    expiry_date: Yup.date(),
    driving_lincense_file_path: Yup.lazy(value => 
        value ? fileValidationSchema.required('Driving License is required') : fileValidationSchema
    ),
    vehicle_number: Yup.string(),
    vehicle_type: Yup.string(),
    citizenship_number: Yup.string().required('Citizenship Number is required'),
    citizenship_issue_date: Yup.date().required('Citizenship Issue Date is required'),
    citizenship_file_path: fileValidationSchema
        .required('Citizenship file is required'),
    pan_number: Yup.string().required('PAN Number is required'),
    pan_file_path: fileValidationSchema
        .required('PAN file is required'),
    passport_number: Yup.string(),
    passport_issue_date: Yup.date(),
    passport_file_path: Yup.lazy(value => 
        value ? fileValidationSchema.required('Passport file is required') : fileValidationSchema
    ),
    police_clearance_file_path: fileValidationSchema,
    term_and_conditions: Yup.boolean()
        .oneOf([true], 'You must accept the terms and conditions')
        .required('Terms and Conditions is required'),
});

export default employeeDocsStoreSchema;