import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Offcanvas,
  Row,
} from "react-bootstrap";
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../../context/ContextProvider";
import itemChallanDetailService from "../../../services/item-challan-detail.service";
import itemChallanService from "../../../services/item-challan.service";
import warehouseService from "../../../services/warehouse.service";
import challanDetailOutStoreSchema from "./item-challan-detail-out-store-schema";
import challanDetailOutUpdateSchema from "./item-challan-detail-out-update-schema";
import rawMaterialService from "../../../services/raw-material.service";

const AddItemChallanDetailOut = ({ setShowAdd, showAdd, id, transaction_id, challan_id, challan_type, sout_date, sout_type, to_id, to_name, shift }) => {

  const navigate = useNavigate();
  const [warehouse, setWarehouse] = useState();
  const [item, setItem] = useState(null);
  const [itemList, setItemList] = useState([]);
  const [challanData, setChallanData] = useState();
  const [itemRemaining, setItemRemaining] = useState();

  const {
    setIsLoading,
    showToast,
    setRefereshData
  } = useStateContext();

  // Function to display errors
  const displayError = (error) => {
    const errArr = Object.keys(error).map((key) => error[key].join(","));
    errArr.forEach((column) => showToast(column, "error"));
  };

  // Fetch Challan data
  const getChallanData = () => {
    itemChallanService.singleView(challan_id)
      .then((response) => setChallanData(response.data.data))
      .catch((error) => displayError(error.response.data.errors));
  };

  // Fetch Item List
  const getItemList = () => {
    rawMaterialService.lists()
      .then((response) => setItemList(response.data.data))
      .catch((error) => displayError(error.response.data.errors));
  };

  // Formik configuration
  const formik = useFormik({
    initialValues: {
      item_id: "",
      quantity: "",
    },
    validationSchema: id ? challanDetailOutUpdateSchema : challanDetailOutStoreSchema,
    onSubmit: (values) => store(values),
  });

  // Function to store data
  const store = (values) => {
    setIsLoading(true);
    let updatedValues = {
      ...values,
      warehouse_id: "1",
      date: challanData.challan_date,
      type: challanData.challan_type,
      to_id: challanData.to_id,
      to_name: challanData.to_name,
      challan_id: challan_id,
      shift: shift,
      item_status: "fresh",
      // Remove batch_id and update as needed
      item_id: item
    };
    itemChallanDetailService.storeChallanOut(updatedValues)
      .then(() => {
        setIsLoading(false);
        setShowAdd(false);
        setRefereshData(true);
        showToast("Item added.", "success");
      })
      .catch((error) => {
        setIsLoading(false);
        displayError(error.response.data.errors);
      });
  };

  // Set Formik initial data
  const setInitialData = () => {
    formik.setValues({ item_id: "", quantity: "" });
  };

  useEffect(() => {
    getChallanData();
    getItemList();
  }, [challan_id]);

  // Options for Select component
  const itemOptions = itemList.map((item) => ({
    value: item.id,
    label: item.text
  }));

  return (
    <Container>
      <Offcanvas
        show={showAdd} // Control Offcanvas visibility
        onHide={() => setShowAdd(false)} // Close Offcanvas when clicking the backdrop
        placement="end"
        tabindex="-1"
        className="overflow-auto"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <Offcanvas.Header class="offcanvas-header">
          <Offcanvas.Title id="offcanvasRightLabel">
            <h4 className="card-title">
              Add Item Out Detail
            </h4>
          </Offcanvas.Title>
          <button
            type="button"
            class="btn-close"
            onClick={() => {
              setShowAdd(false);
            }}
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md="12">
                <Form.Label>Item Name</Form.Label>
                <Select
                  options={itemOptions}
                  value={itemOptions.find(option => option.value === item)}
                  onChange={(selectedOption) => {
                    setItem(selectedOption.value);
                    formik.setFieldValue('item_id', selectedOption.value);
                  }}
                  onBlur={formik.handleBlur}
                  className={`form-control p-0`}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.item_id}
                </Form.Control.Feedback>
              </Col>
              <Col md="12">
                <Form.Label>Quantity</Form.Label>
                <Form.Control
                  type="number"
                  name="quantity"
                  value={formik.values.quantity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.quantity && !!formik.errors.quantity}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.quantity}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>
        <Button className='m-3 mb-0' variant="outline-secondary" type="submit" onClick={formik.handleSubmit}>
          {id !== undefined ? "Update" : "Save"}
        </Button>
        <Button className='m-3 mb-0' variant="outline-warning" onClick={setInitialData}>
          Reset Data
        </Button>
        <Button
          className='m-3 mb-0' variant="outline-secondary"
          onClick={() => {
            setShowAdd(false);
          }}
        >
          Cancel
        </Button>
      </Offcanvas>
    </Container>
  );
};

export default AddItemChallanDetailOut;
