import { useFormik } from "formik";
import React, { useState, useEffect, Fragment } from "react";
import {
    Button,
    Col,
    Container,
    Form,
    Offcanvas,
    Row,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { AiFillDelete, AiFillGooglePlusCircle, AiFillPlusCircle } from "react-icons/ai";
import DataTable from "react-data-table-component";
import { HiMiniViewfinderCircle } from "react-icons/hi2";
import { useStateContext } from "../../context/ContextProvider";
import { usePermissionContext } from "../../context/PremissionContext";
import stsaleCloseService from "../../services/stsale-close.service";
import rawMaterialService from "../../services/raw-material.service";
import coffeeCloseService from "../../services/coffee-close.service";

const OpCoffeeCloseDetail = ({ close_data }) => {
    const { id, type } = useParams();
    const navigate = useNavigate();
    const { permissions } = usePermissionContext();
    const {
        dtColorMode,
        setIsLoading,
        setPageTitle,
        showToast,
        setRefereshData,
        refereshData,
    } = useStateContext();

    const permissionNames = {
        mor: {
            create: "Create Mor Stsale Detail",
            update: "Update Mor Stsale Detail",
            view: "View Mor Stsale Detail",
            delete: "Delete Mor Stsale Detail",
            restore: "Restore Mor Stsale Detail",
            purge: "Purge Mor Stsale Detail",
        },
        even: {
            create: "Create Even Stsale Detail",
            update: "Update Even Stsale Detail",
            view: "View Even Stsale Detail",
            delete: "Delete Even Stsale Detail",
            restore: "Restore Even Stsale Detail",
            purge: "Purge Even Stsale Detail",
        },
    };

    const getPermissions = (type) => {
        return {
            create: permissions.some(
                (permission) => permission.name === permissionNames[type].create
            ),
            update: permissions.some(
                (permission) => permission.name === permissionNames[type].update
            ),
            view: permissions.some(
                (permission) => permission.name === permissionNames[type].view
            ),
            delete: permissions.some(
                (permission) => permission.name === permissionNames[type].delete
            ),
            restore: permissions.some(
                (permission) => permission.name === permissionNames[type].restore
            ),
            purge: permissions.some(
                (permission) => permission.name === permissionNames[type].purge
            ),
        };
    };

    const {
        create: createPermission,
        update: updatePermission,
        view: viewPermission,
        delete: deletePermission,
        restore: restorePermission,
        purge: purgePermission,
    } = getPermissions(type);

    const displayError = (error) => {
        const errArr = Object.keys(error).map((key) => error[key].join(","));
        errArr.forEach((column) => showToast(column, "error"));
    };

    const [data, setData] = useState([]);

    const [itemList, setItemList] = useState([]);
    const getItemList = () => {
        rawMaterialService
            .lists()
            .then((response) => {
                setItemList(response.data.data);
            })
            .catch((error) => {
                displayError(error.response.data.errors);
            });
    };

    useEffect(() => {
        getItemList();
    }, [refereshData]);

    const [forms, setForms] = useState([{
        item_id: '',
        open_amount: '',
        close_amount: ''
    }]);

    const addForm = () => {
        setForms([...forms, {
            item_id: '',
            open_amount: '',
            close_amount: ''
        }]);
    };

    const removeForm = (index) => {
        setForms(forms.filter((_, i) => i !== index));
    };

    const handleChange = (index, e) => {
        const { name, value } = e.target;
        const newForms = [...forms];
        newForms[index] = { ...newForms[index], [name]: value };
        setForms(newForms);
    };


    const handleBlur = (index, e) => {
        // Handle onBlur if necessary
    };

    const handleSubmitAll = () => {
        setIsLoading(true);
        if (forms.length > 0) {
            coffeeCloseService.storeAll({
                main: close_data,
                detail: forms
            }, type).then((response) => {
                setIsLoading(false);
                navigate('/app/operation/dashboard');
            }).catch((error) => {
                setIsLoading(false);
                displayError(error.response.data.errors);
            })
        } else {
            setIsLoading(false);
            showToast("No data to submit", "warning");
        }
    };

    return (
        <Fragment>
            <div>
                {forms.map((form, index) => (
                    <Form key={index} noValidate>
                        <Row>
                            <Col md="5">
                                <Form.Label htmlFor={`item_id_${index}`} className="h6">Item Name</Form.Label>
                                <Form.Select
                                    className="form-control"
                                    id={`item_id_${index}`}
                                    name="item_id"
                                    value={form.item_id}
                                    onChange={(e) => handleChange(index, e)}
                                    onBlur={(e) => handleBlur(index, e)}
                                >
                                    <option value="">Select Specific Item</option>
                                    {itemList.map((item) => (
                                        <option key={item.id} value={item.id}>{item.text}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col md="2">
                                <Form.Label htmlFor={`open_amount_${index}`} className="h6">Open Amount</Form.Label>
                                <Form.Control
                                    type="number"
                                    id={`open_amount_${index}`}
                                    name="open_amount"
                                    value={form.open_amount}
                                    onChange={(e) => handleChange(index, e)}
                                    onBlur={(e) => handleBlur(index, e)}
                                />
                            </Col>
                            <Col md="2">
                                <Form.Label htmlFor={`close_amount_${index}`} className="h6">Close Amount</Form.Label>
                                <Form.Control
                                    type="number"
                                    id={`close_amount_${index}`}
                                    name="close_amount"
                                    value={form.close_amount}
                                    onChange={(e) => handleChange(index, e)}
                                    onBlur={(e) => handleBlur(index, e)}
                                />
                            </Col>
                            <Col className="d-flex justify-content-end p-2 mt-3 gap-2">
                                <a className="btn btn-sm btn-outline-danger d-flex p-2"
                                    onClick={() => removeForm(index)}
                                >
                                    <AiFillDelete style={{ fontSize: "1.3rem" }} />
                                </a>
                                <Button variant="btn btn-outline-primary" onClick={addForm}>
                                    <AiFillPlusCircle style={{ fontSize: "1.3rem" }} />
                                    Add New
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                ))}
                <div className="mt-3 text-end">
                    <Button onClick={handleSubmitAll}>Submit All Data</Button>
                </div>
            </div>
        </Fragment>
    );
};

export default OpCoffeeCloseDetail;
