import React, { memo, Fragment, useState, useEffect } from "react";
import {
  AiFillFunnelPlot,
} from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import { Col, Card, Button, Form, Row } from "react-bootstrap";
//components
import { useStateContext } from "../../context/ContextProvider";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import departmentService from "../../services/department.service";
import stockCloseDetailService from "../../services/stock-close-detail.service";
import imsReportService from "../../services/ims-report.service";

const InventorySummary = memo(() => {
  const navigate = useNavigate();
  const {
    dtColorMode,
    showToast,
    setPageTitle,
    setIsLoading,
    refereshData,
    setRefereshData,
  } = useStateContext();

  // Data for the datatable
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [totalAmountSum, setTotalAmountSum] = useState(0);

  const getCellColor = (percentage) => {
    if (percentage >= 90) {
      return "#ff6666";
    }
    else if (percentage >= 70) {
      return "#ffcc66";
    }
    else if (percentage >= 50) {
      return "#ffff66";
    }
    else if (percentage >= 30) {
      return "#66ff66";
    }
    else {
      return "#66b3ff";
    }
  }

  // Datatable columns
  const tableColumns = React.useMemo(
    () => [
      {
        id: "sno",
        name: (
          <span>
            S.N </span>),
        cell: (row, index) => index + 1,
        minWidth: '50px',
        maxWidth: '120px'
      },
      {
        id: "item_name",
        name: (
          <span>
            Item Name</span>),
        selector: (row) => row.raw_material_name,
        sortable: true,
        grow: 3,
      },
      {
        id: "purchase_price",
        name:
          (<span>Purchase Price</span>),
        selector: (row) => row.purchase_price,
        sortable: true,
        maxWidth: '150px'
      },
      {
        id: "moq",
        name: (
          <span>MOQ Consumption
          </span>
        ),
        selector: (row) => row.moq,
        sortable: true,
        maxWidth: '80px'
      },
      {
        id: "safety_factor",
        name: (
          <span>
            Safety Factor</span>),
        selector: (row) => row.safety_factor,
        sortable: true,
        maxWidth: '150px'
      },
      {
        id: "lead_time",
        name: (<span>Lead Time</span>),
        selector: (row) => row.lead_time,
        sortable: true,
        maxWidth: '80px '
      },
      {
        id: "max_level",
        name: (
          <span>
            Max Level</span>),
        selector: (row) => row.max_level,
        sortable: true,
        maxWidth: '150px'
      },
      {
        id: "required_seven_days",
        name: (
          <span>
            Stock required as per 7 days</span>),
        selector: (row) => row.required_seven_days,
        sortable: true,
        maxWidth: '150px'
      },
      {
        id: "opening_stock",
        name: (
          <span>
            Opening Stock</span>),
        selector: (row) => row.opening_stock,
        sortable: true,
        maxWidth: '150px'
      },
      {
        id: "total_in_store",
        name: (
          <span>
            Total avaiable in store</span>),
        selector: (row) => parseFloat(row.opening_stock) + parseInt(row.purchase_quantity) - parseInt(row.total_out_quantity) - parseInt(row.damage_quantity),
        sortable: true,
        maxWidth: '150px'
      },
      {
        id: "in_store_percent",
        name: (
          <span>
            Avail Stock in Store</span>),
        selector: (row) => row.in_store_percent,
        sortable: true,
        maxWidth: '150px'
      },
      {
        id: "moq",
        name: (
          <span>
            MOQ</span>),
        selector: (row) => row.moq,
        sortable: true,
        maxWidth: '150px'
      },
      {
        id: "total_stock",
        name: (
          <span>
            Total Stock
          </span>),
        selector: (row) => row.total_stock,
        sortable: true,
        maxWidth: '150px'
      },
      {
        id: "avail_stock_in_percent",
        name: (
          <span>
            Avail Stock in %</span>),
        selector: (row) => row.avail_stock_in_percent,
        sortable: true,
        maxWidth: '150px'
      },
      {
        id: "current_avg",
        name: (
          <span>
            Current Average</span>),
        selector: (row) => row.current_avg,
        sortable: true,
        maxWidth: '150px'
      },
      {
        id: "current_avg_days",
        name: (
          <span>
            Current Average Days</span>),
        selector: (row) => row.current_avg_days,
        sortable: true,
        maxWidth: '150px',
        right: true,
        conditionalCellStyles: [
          {
            when: (row) => row.tod_software_amount > ((shift == "mor" ? (row.yester_mor_close_amount - row.yester_mor_transfer_amount + row.yester_even_transfer_amount) : (row.yester_even_close_amount - row.yester_even_transfer_amount + row.yester_mor_transfer_amount)) + row.total_quantity) - (row.tod_close_amount + row.damaged_amount),
            style: {
              backgroundColor: 'rgba(63, 195, 128, 0.9)',
              color: 'black',
              '&:hover': {
                cursor: 'pointer',
              },
            },
          },
          {
            when: (row) => row.tod_software_amount < ((shift == "mor" ? (row.yester_mor_close_amount - row.yester_mor_transfer_amount + row.yester_even_transfer_amount) : (row.yester_even_close_amount - row.yester_even_transfer_amount + row.yester_mor_transfer_amount)) + row.total_quantity) - (row.tod_close_amount + row.damaged_amount),
            style: {
              backgroundColor: '#C70039',
              color: 'black',
              '&:hover': {
                cursor: 'pointer',
              },
            },
          },
          {
            when: (row) => row.tod_software_amount === ((shift == "mor" ? (row.yester_mor_close_amount - row.yester_mor_transfer_amount + row.yester_even_transfer_amount) : (row.yester_even_close_amount - row.yester_even_transfer_amount + row.yester_mor_transfer_amount)) + row.total_quantity) - (row.tod_close_amount + row.damaged_amount),
            style: {
              backgroundColor: 'rgba(0, 0, 255, 0.5)',
              color: 'white',
              '&:hover': {
                cursor: 'pointer',
              },
            },
          },
        ],
      },
      {
        id: "stock_ageing",
        name: (
          <span>
            Stock Ageing</span>),
        selector: (row) => row.stock_ageing,
        sortable: true,
        maxWidth: '150px'
      },
      {
        id: "remark",
        name: (
          <span>
            Remark</span>),
        selector: (row) => row.remark,
        sortable: true,
        maxWidth: '150px'
      },
      {
        id: "action",
        name: (
          <span>
            Action</span>),
        selector: (row) => row.action,
        sortable: true,
        maxWidth: '150px'
      },
      {
        id: "check",
        name: (
          <span>
            Check</span>),
        selector: (row) => row.check,
        sortable: true,
        maxWidth: '150px'
      },
    ],
    [data]
  );

  const getDataList = (page, rowsPerPage) => {
    setIsLoading(true);
    imsReportService
      .ims()
      .then((response) => {
        const fetchedData = response.data.data;
        setData(fetchedData);
        setIsLoading(false);
      })
      .catch((error) => {
        showToast(error.response.data.message, "error");
        setIsLoading(false);
      });
  };

  const handleExport = () => {
    setIsLoading(true);
    showToast("Data exported successfully", "success");
    setIsLoading(false);
  };

  const [shift, setShift] = useState("");
  const [entryDate, setEntryDate] = useState("");

  const handleShiftChange = (e) => {
    setShift(e.target.value);
  };

  const handleEntryDateChange = (e) => {
    setEntryDate(e.target.value);
  };

  const handleSearchSubmit = () => {
    setIsLoading(true);
    stockCloseDetailService.day_summary({
      entry_date: entryDate
    }).then((response) => {
      setIsLoading(false);
      const fetchedData = response.data.data;
      setData(fetchedData);
    }).catch((error) => {
      setIsLoading(false);
    })
  }

  useEffect(() => {
    getDataList(currentPage, perPage);
    setPageTitle("Inventory");
  }, [currentPage, perPage, refereshData]);

  return (
    <>
      <Fragment>
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">Inventory</h4>
            </div>
          </Card.Header>
          <Card.Body>
            <div>
              <DataTable
                className="data-table"
                columns={tableColumns}
                data={data}
                pagination
                paginationServer
                paginationPerPage={perPage}
                paginationComponentOptions={{
                  selectAllRowsItem: true,
                  selectAllRowsItemText: "All",
                }}
                paginationRowsPerPageOptions={[10, 20, 30, 50]}
                paginationServerPage={currentPage}
                paginationTotalRows={totalRows}
                onChangePage={(page) => {
                  setCurrentPage(page);
                }}
                onChangeRowsPerPage={(newPerPage, page) => {
                  setPerPage(newPerPage === "All" ? data.length : newPerPage);
                  setCurrentPage(page);
                }}
                theme={dtColorMode == "dark" ? "dark" : ""}
                selectableRowsHighlight
                highlightOnHover
                fixedHeader
                subHeader
                subHeaderComponent={
                  <>
                  </>
                }
              />
            </div>
          </Card.Body>
        </Card>
      </Fragment>
    </>
  );
});

InventorySummary.displayName = "InventorySummary";
export default InventorySummary;
