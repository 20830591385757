import axiosClient from "../axios";
import http from "./http-common";

class AuthenticationService {

    login(logindata) {
        return axiosClient.post("/auth/login", logindata);
    }
    get(id) {
        return axiosClient.get(`/auth/${id}`);
    }
    logout() {
        return axiosClient.get("/auth/logout");
    }
    create(data) {
        return axiosClient.post("/users", data);
    }
    update(id, data) {
        return axiosClient.put(`/users/${id}`, data);
    }
    getUserID(parent_id) {
        return axiosClient.get(`/users?like[0]=parent_id,${parent_id}`);
    }
    delete(id) {
        return axiosClient.delete(`/tutorials/${id}`);
    }
    deleteAll() {
        return axiosClient.delete(`/tutorials`);
    }
    findByTitle(title) {
        return axiosClient.get(`/tutorials?title=${title}`);
    }
    getUserPermissions() {
        return axiosClient.get(`/system_acl/user/permissions`);
    }
}

export default new AuthenticationService();
