import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormCheck,
  Row,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "../../context/ContextProvider";
import mistakeTicketService from "../../services/mistake-ticket.service";
import mistakeTicketStoreSchema from "./mistake-ticket-store-schema";
import mistakeTicketUpdateSchema from "./mistake-ticket-update-schema";
import attributeService from "../../services/attribute.service";

const AddMistakeTicket = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setIsLoading, setPageTitle, showToast } = useStateContext();

  const data = {
    mistake_type: "",
    pervious_data: "",
    current_data: "",
    remarks: "",
    data_date: "",
    entry_date: "",
    status: "Active",
    created_by: "",
    updated_by: "",
  };

  /**
   * The function `displayError` takes an error object as input and displays each error message as a
   * toast notification.
   * @param error - The `error` parameter is an object that contains error messages for different
   * columns. Each key in the `error` object represents a column, and the corresponding value is an
   * array of error messages for that column.
   */
  const displayError = (error) => {
    let errArr = [];
    errArr = Object.keys(error).map((key) => {
      return error[key].join(",");
    });
    errArr.map((column) =>
      showToast(column, "error")
    );
  }

  const store = (values) => {
    setIsLoading(true);
    mistakeTicketService
      .store(values)
      .then((response) => {
        setIsLoading(false);
        navigate("/app/mistake_ticket");
        showToast(`New Mistake Ticket added.`, "success");
      })
      .catch((error) => {
        setIsLoading(false);
        displayError(error.response.data.errors);
      });
  };

  const update = (values) => {
    setIsLoading(true);
    mistakeTicketService
      .update(id, values)
      .then((response) => {
        setIsLoading(false);
        navigate("/app/mistake_ticket");
        showToast(`Mistake Ticket updated.`, "success");
      })
      .catch((error) => {
        setIsLoading(false);
        displayError(error.response.data.errors);
      });
  };

  const submitForm = (values) => {
    id === undefined || id === "0" ? store(values) : update(values);
  };

  const setInitialData = () => {
    if (id !== undefined) {
      setIsLoading(true);
      mistakeTicketService
        .singleView(id)
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            formik.setValues(response.data.data);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          showToast(error.response.data.message, "error");
          navigate("/app/mistake_ticket");
        });
    } else {
      formik.setValues(data);
    }
  };

  const [mistakeTypeList, setMistakeTypeList] = useState();

  //get the mistake type
  const getMistakeType = () => {
    attributeService.childrenList('Mistake Type')
      .then((response) => {
        setMistakeTypeList(response.data);
      })
      .catch((error) => {
        displayError(error.response.data.errors);
      })
  }

  useEffect(() => {
    setPageTitle("Mistake Tickets");
    setInitialData();
    getMistakeType();
  }, [setPageTitle]);

  const formik = useFormik({
    initialValues: data,
    validationSchema: id !== undefined ? mistakeTicketUpdateSchema : mistakeTicketStoreSchema,
    onSubmit: submitForm,
  });

  return (
    <Container>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">
              {id ? "Edit Mistake Ticket" : "Add Mistake Ticket"}
            </h4>
          </div>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Row className="mb-3">
              <h5>Basic Information</h5>
              <hr className="my-3" />
              <Col md="6" className="p-2">
                <Form.Label htmlFor="validationDefault041">
                  Mistake Type
                </Form.Label>
                <Form.Select
                  className="form-control form-select"
                  name="mistake_type"
                  value={formik.values.mistake_type}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.mistake_type && !!formik.errors.mistake_type
                  }
                >
                  <option defaultValue="">Select the mistake type</option>
                  {mistakeTypeList ? (
                    <>
                      {
                        mistakeTypeList.map((list) => (
                          <option key={list.id} value={list.text}>{list.text}</option>
                        ))
                      }
                    </>
                  ) :
                    null
                  }
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.mistake_type}
                </Form.Control.Feedback>
              </Col>
              <Col md="6" className="p-2">
                <Form.Label htmlFor="validationCustom01">
                  Pervious Data
                </Form.Label>
                <Form.Control
                  type="text"
                  name="pervious_data"
                  value={formik.values.pervious_data}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.pervious_data && !!formik.errors.pervious_data
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.pervious_data}
                </Form.Control.Feedback>
              </Col>
              <Col md="6" className="p-2">
                <Form.Label htmlFor="validationCustom02">
                  Current Data
                </Form.Label>
                <Form.Control
                  type="text"
                  name="current_data"
                  value={formik.values.current_data}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.current_data && !!formik.errors.current_data
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.current_data}
                </Form.Control.Feedback>
              </Col>
              <Col md="6" className="p-2">
                <Form.Label htmlFor="validationCustomUsername01">
                  Remarks
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="remarks"
                  value={formik.values.remarks}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.remarks &&
                    !!formik.errors.remarks
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.remarks}
                </Form.Control.Feedback>
              </Col>
              <Col md="6" className="p-2">
                <Form.Label htmlFor="validationCustom03">
                  Data date
                </Form.Label>
                <Form.Control
                  type="date"
                  name="data_date"
                  value={formik.values.data_date}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.data_date && !!formik.errors.data_date
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.data_date}
                </Form.Control.Feedback>
              </Col>
              <Col md="6" className="mb-3 p-2">
                <Form.Check.Label htmlFor="flexSwitchCheckDefault">
                  Ticket Status
                </Form.Check.Label>
                <Row className="mt-3">
                  <Col className="d-flex gap-3 justify-content-start pl-5">
                    <Form.Check>
                      <FormCheck.Input
                        type="radio"
                        name="status"
                        className=""
                        id="disabledRadio1"
                        disabled=""
                        value="Active"
                        onChange={formik.handleChange}
                        checked={formik.values.status === "Active"}
                        isInvalid={
                          formik.touched.status && !!formik.errors.status
                        }
                      />
                      <FormCheck.Label className="" htmlFor="disabledRadio1">
                        Active
                      </FormCheck.Label>
                    </Form.Check>
                    <Form.Check>
                      <FormCheck.Input
                        type="radio"
                        name="status"
                        className=""
                        id="disabledRadio2"
                        disabled=""
                        value="Inactive"
                        onChange={formik.handleChange}
                        checked={formik.values.status === "Inactive"}
                        isInvalid={
                          formik.touched.status && !!formik.errors.status
                        }
                      />
                      <FormCheck.Label className="" htmlFor="disabledRadio2">
                        Inactive
                      </FormCheck.Label>
                    </Form.Check>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.status}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="col-12 d-flex gap-2 justify-content-end">
              <Button variant="btn btn-primary" type="submit">
                {id !== undefined ? "Update" : "Save"}
              </Button>
              <Button variant="btn btn-secondary" onClick={setInitialData}>
                Reset Data
              </Button>
              <Button
                variant="btn btn-secondary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AddMistakeTicket;
