import React, { createContext, useState, useEffect, useContext } from 'react';
import CryptoJS from 'crypto-js';

const secretKey = 'YourSecretKey'; // Replace with your secret key for encryption

const PermissionContext = createContext({
    permissions: [],
    setPermissions: () => { }
});

export const PermissionProvider = ({ children }) => {

    const [permissions, setPermissionsState] = useState(() => {
        const encryptedPermissions = localStorage.getItem('en_perm');
        if (encryptedPermissions) {
            try {
                const bytes = CryptoJS.AES.decrypt(encryptedPermissions, secretKey);
                const decryptedPermissions = bytes.toString(CryptoJS.enc.Utf8);
                return JSON.parse(decryptedPermissions);
            } catch (error) {
                console.error('Error decrypting permissions:', error);
                return [];
            }
        }
        return [];
    });

    const setPermissions = (permissions) => {
        if (permissions && permissions.length > 0) {
            try {
                const encryptedPermissions = CryptoJS.AES.encrypt(JSON.stringify(permissions), secretKey).toString();
                localStorage.setItem('en_perm', encryptedPermissions);
                setPermissionsState(permissions);
            } catch (error) {
                console.error('Error encrypting permissions:', error);
            }
        } else {
            localStorage.removeItem('en_perm');
            setPermissionsState([]);
        }
    };

    return (
        <PermissionContext.Provider value={{ permissions, setPermissions }}>
            {children}
        </PermissionContext.Provider>
    );
};

export const usePermissionContext = () => useContext(PermissionContext);


// const PermissionProvider = ({ children }) => {
//     const [permissions, setPermissions] = useState([]);

//     // Function to encrypt and save permissions to localStorage
//     const savePermissionsToStorage = (permissions) => {
//         try {
//             const encryptedPermissions = CryptoJS.AES.encrypt(JSON.stringify(permissions), 'secret_key').toString();
//             localStorage.setItem('encrypted_permissions', encryptedPermissions);
//         } catch (error) {
//             console.error('Error saving permissions:', error);
//         }
//     };

//     // Function to decrypt permissions from localStorage
//     const loadPermissionsFromStorage = () => {
//         try {
//             const encryptedPermissions = localStorage.getItem('encrypted_permissions');
//             if (encryptedPermissions) {
//                 const bytes = CryptoJS.AES.decrypt(encryptedPermissions, 'secret_key');
//                 const decryptedPermissions = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//                 setPermissions(decryptedPermissions);
//             }
//         } catch (error) {
//             console.error('Error loading permissions:', error);
//         }
//     };

//     // Load permissions from localStorage on component mount
//     useEffect(() => {
//         loadPermissionsFromStorage();
//     }, []);

//     // Update localStorage whenever permissions change
//     useEffect(() => {
//         savePermissionsToStorage(permissions);
//     }, [permissions]);

//     return (
//         <PermissionContext.Provider value={{ permissions, setPermissions }}>
//             {children}
//         </PermissionContext.Provider>
//     );
// };

// export { PermissionProvider, PermissionContext };
