import axiosClient from "../axios";

class StsaleCloseService {
    storeAll(values, type) {
        return axiosClient.post(`/${type}_stsales/store_all`, values);
    }
    lists() {
        return axiosClient.get('/mor_stsales/list');
    }
    //to get all data
    view(page, rowsPerPage, type) {
        return axiosClient.get(`/${type}_stsales?page=${page}&pp=${rowsPerPage}`);
    }
    //to store data
    store(values, type) {
        return axiosClient.post(`/${type}_stsales`, values);
    }
    //to update data
    update(uuid, values, type) {
        return axiosClient.put(`/${type}_stsales/${uuid}`, values);
    }
    // to simple search 
    search(url, type) {
        return axiosClient.get(`/${type}_stsales?${url}`);
    }
    //to search single data
    singleView(uuid, type) {
        return axiosClient.get(`/${type}_stsales/${uuid}`);
    }
    //to delete single data
    delete(uuid, type) {
        return axiosClient.delete(`/${type}_stsales/` + uuid, { close_id: uuid });
    }
    //to restore single data
    restore(uuid, type) {
        return axiosClient.delete(`/${type}_stsales/${uuid}/restore`);
    }
    //to prune single data
    prune(uuid, type) {
        return axiosClient.delete(`/${type}_stsales/${uuid}/prune`);
    }
    //to delete selected row data
    deleteAll(selectedRowsUid, type) {
        return axiosClient.post(`/${type}_stsales/deleteall`, { 'ids': selectedRowsUid });
    }
    //to restore selected row data
    restoreAll(selectedRowsUUID, type) {
        return axiosClient.post(`/${type}_stsales/restoreall`, { 'ids': selectedRowsUUID });
    }
    //to prune selected row data
    pruneAll(selectedRowsUUID, type) {
        return axiosClient.post(`/${type}_stsales/pruneall`, { 'ids': selectedRowsUUID });
    }
    //to get deleted data
    viewDeleted(type) {
        return axiosClient.get(`/${type}_stsales`);
    }
}
export default new StsaleCloseService();