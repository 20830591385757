import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';

//router
import { BrowserRouter } from 'react-router-dom'
//store
import { Provider } from 'react-redux';
//reducer
import Store from './store'

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
     <Provider store={Store}>
            <App />
        </Provider>
    </BrowserRouter>
  </React.StrictMode>
);