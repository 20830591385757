import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Collapse,
  ListGroup,
  ListGroupItem,
  Offcanvas,
  Row,
  Col,
} from "react-bootstrap";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import Select from "react-select";
import { useStateContext } from "../../context/ContextProvider";
import warehouseService from "../../services/warehouse.service";
import vendorService from "../../services/vendor.service";
import Flatpickr from "react-flatpickr";
import rawMaterialService from "../../services/raw-material.service";

export default function PurchaseReturnFilter(props) {

  const { showToast } = useStateContext();

  // OffCanvas settings and functions
  const [openDisplay, setOpenDisplay] = useState(false);
  const [openFilter, setOpenFilter] = useState(true);
  // Status select list options
  const statusOptions = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  const displayError = (error) => {
    let errArr = [];
    errArr = Object.keys(error).map((key) => {
      return error[key].join(",");
    });
    errArr.map((column) =>
      showToast(column, "error")
    );
  }

  const handleStatusChange = (selectedOptions) => {
    props.handleInputChange("status", selectedOptions);
  };

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const [startDate, endDate] = selectedDates;

      const formatDate = (date) => {
        return date.getFullYear() + '-' +
          ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
          ('0' + date.getDate()).slice(-2);
      };

      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      props.handleInputChange('entry_date', [formattedStartDate, formattedEndDate]);
    }
  };

  const handleInvoiceDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const [startDate, endDate] = selectedDates;

      const formatDate = (date) => {
        return date.getFullYear() + '-' +
          ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
          ('0' + date.getDate()).slice(-2);
      };

      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      props.handleInputChange('invoice_date', [formattedStartDate, formattedEndDate]);
    }
  };

  const [itemList, setItemList] = useState();

  const getItemType = () => {
    rawMaterialService.lists()
      .then((response) => {
        setItemList(response.data.data);
      })
  }
  const items = itemList ? (
    itemList.map((item) => ({ value: item.id, label: item.text }))
  ) : (
    <>none</>
  );

  const handleItemChange = (selectedOptions) => {
    props.handleInputChange("item_id", selectedOptions);
  };

  const [vendorList, setVendorList] = useState();

  const getVendorType = () => {
    vendorService.lists()
      .then((response) => {
        setVendorList(response.data.data);
      })
  }
  const vendors = vendorList ? (
    vendorList.map((vendor) => ({ value: vendor.id, label: vendor.text }))
  ) : (
    <>none</>
  );

  const handleVendorChange = (selectedOptions) => {
    props.handleInputChange("vendor_id", selectedOptions);
  };

  useEffect(() => {
    getItemType();
    getVendorType();
  }, []);

  return (
    <Offcanvas
      show={props.showOffcanvas} // Control Offcanvas visibility
      onHide={props.handleOffcanvasToggle} // Close Offcanvas when clicking the backdrop
      placement="end"
      target="offcanvasRight"
      tabindex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <Offcanvas.Header class="offcanvas-header">
        <Offcanvas.Title id="offcanvasRightLabel">
          Advanced Filters and Display
        </Offcanvas.Title>
        <button
          type="button"
          class="btn-close"
          onClick={() => {
            props.handleOffcanvasToggle();
          }}
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Button
          href="#"
          onClick={() => setOpenDisplay(!openDisplay)}
          aria-controls="example-collapse-text"
          aria-expanded={openDisplay}
          bsPrefix="bg-transparent iq-custom-collapse w-100 d-flex justify-content-between pb-3"
        >
          <h5 className="mb-0">
            Show/Hide Columns{" "}
            {openDisplay ? (
              <IoIosArrowDropdownCircle style={{ fontSize: "1.5rem" }} />
            ) : (
              <IoIosArrowDroprightCircle style={{ fontSize: "1.5rem" }} />
            )}
          </h5>
        </Button>
        <Collapse in={openDisplay}>
          <div id="iq-product-filter-01">
            <div className="mt-2">
              <div className="product-ratings mt-2">
                <ListGroup>
                  {props.tableColumns.map((column) => {
                    if (
                      column.id === "sno" ||
                      column.id === "status" ||
                      column.id === "actions"
                    ) {
                      return null;
                    }
                    return (
                      <div key={column.id}>
                        <ListGroupItem as="label" className="border-0">
                          <input
                            className="form-check-input m-1"
                            type="checkbox"
                            defaultChecked={!column.omit}
                            onChange={() =>
                              props.handleHideColumnsChange(
                                column.id,
                                column.omit
                              )
                            }
                          />
                          {column.name}
                        </ListGroupItem>
                      </div>
                    );
                  })}
                </ListGroup>
              </div>
            </div>
          </div>
        </Collapse>
        <Button
          href="#"
          onClick={() => setOpenFilter(!openFilter)}
          aria-controls="example-collapse-text"
          aria-expanded={openFilter}
          bsPrefix="bg-transparent iq-custom-collapse w-100 d-flex justify-content-between pb-3"
        >
          <h5 className="mb-0 mt-3">
            Filters
            {openFilter ? (
              <IoIosArrowDropdownCircle style={{ fontSize: "1.5rem" }} />
            ) : (
              <IoIosArrowDroprightCircle style={{ fontSize: "1.5rem" }} />
            )}
          </h5>
        </Button>
        <Collapse in={openFilter}>
          <div id="iq-product-filter-01">
            <div className="mt-2">
              <div className="product-ratings m-2">
                <Form onSubmit={() => props.handleSearchFilter("filterSearch")}>
                  <Form.Label htmlFor="validationCustom01">
                    <h5>Type of Data</h5>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    className="form-select"
                    name="dataStatus"
                    aria-label=".form-select example"
                    onChange={(e) => {
                      props.handleInputChange(e.target.name, e.target.value);
                    }}
                    defaultValue={"is_del=0"}
                    value={props.searchFilter.dataStatus}
                  >
                    <option defaultValue="is_del=0">Only Live</option>
                    <option defaultValue="is_del=1">Deleted Only</option>
                    <option defaultValue="is_del=2">Display All</option>
                  </Form.Control>

                  <Form.Label htmlFor="validationCustom01">
                    <h6> Invoice Number</h6>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="invoice_no"
                    name="invoice_no"
                    value={props.searchFilter.invoice_no}
                    onChange={(e) => {
                      props.handleInputChange(e.target.name, e.target.value);
                    }}
                  />
                  <Form.Label htmlFor="validationCustom01">
                    <h6> Challan Number</h6>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="challan_no"
                    name="challan_no"
                    value={props.searchFilter.challan_no}
                    onChange={(e) => {
                      props.handleInputChange(e.target.name, e.target.value);
                    }}
                  />
                  <Form.Label htmlFor="validationCustom01">
                    <h6>Vendor Name</h6>
                  </Form.Label>
                  <Select
                    isMulti
                    name="vendor_id"
                    options={vendors}
                    value={props.searchFilter.vendor_id}
                    onChange={handleVendorChange}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                  <Form.Label htmlFor="validationCustom01">
                    <h6>Item Name</h6>
                  </Form.Label>
                  <Select
                    isMulti
                    name="item_id"
                    options={items}
                    value={props.searchFilter.item_id}
                    onChange={handleItemChange}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                  <Form.Label htmlFor="validationCustom01">
                    <h6>Entry Date</h6>
                  </Form.Label>
                  <Flatpickr
                    style={{
                      width: "330px",
                      height: "40px"
                    }}
                    options={{
                      mode: 'range', // Enables range selection
                      dateFormat: 'Y-m-d', // Format of the selected dates
                    }}
                    name="entry_date"
                    value={props.searchFilter.entry_date} // Reflects the selected date range from state
                    onChange={(selectedDates) => {
                      // `selectedDates` will be an array containing the selected range [startDate, endDate]
                      handleDateChange(selectedDates);
                    }}
                  />
                  <Form.Label htmlFor="validationCustom01">
                    <h6>Invoice Date</h6>
                  </Form.Label>
                  <Flatpickr
                    style={{
                      width: "330px",
                      height: "40px"
                    }}
                    options={{
                      mode: 'range', // Enables range selection
                      dateFormat: 'Y-m-d', // Format of the selected dates
                    }}
                    name="invoice_date"
                    value={props.searchFilter.invoice_date} // Reflects the selected date range from state
                    onChange={(selectedDates) => {
                      // `selectedDates` will be an array containing the selected range [startDate, endDate]
                      handleInvoiceDateChange(selectedDates);
                    }}
                  />
                  <Form.Label htmlFor="validationCustom01">
                    <h6> Batch ID</h6>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="batch_id"
                    name="batch_id"
                    value={props.searchFilter.batch_id}
                    onChange={(e) => {
                      props.handleInputChange(e.target.name, e.target.value);
                    }}
                  />
                  <Form.Label htmlFor="validationCustom01">
                    <h6> Amount</h6>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="amount"
                    name="amount"
                    value={props.searchFilter.amount}
                    onChange={(e) => {
                      props.handleInputChange(e.target.name, e.target.value);
                    }}
                  />
                  <Form.Label htmlFor="validationCustom01">
                    <h6> Remarks</h6>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="remarks"
                    name="remarks"
                    value={props.searchFilter.remarks}
                    onChange={(e) => {
                      props.handleInputChange(e.target.name, e.target.value);
                    }}
                  />
                  {/* <Form.Label htmlFor="validationCustom01">
                    <h6>Status</h6>
                  </Form.Label>
                  <Select
                    isMulti
                    name="status"
                    options={statusOptions}
                    value={props.searchFilter.status}
                    onChange={handleStatusChange}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  /> */}
                </Form>
              </div>
            </div>
          </div>
        </Collapse>
      </Offcanvas.Body>
      <Button
        variant="outline-secondary"
        className="m-3 mb-0"
        type="submit"
        onClick={() => props.handleSearchFilter("filterSearch")}
      >
        Apply Filters
      </Button>
      <Button
        className="m-3"
        variant="outline-info"
      //onClick={handleExport}
      >
        Export
      </Button>
    </Offcanvas>
  );
}
