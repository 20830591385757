// router

// scss
import "./assets/scss/hope-ui.scss"
import "./assets/scss/dark.scss"
import "./assets/scss/rtl.scss"
import "./assets/scss/custom.scss"
import "./assets/scss/customizer.scss"
import 'flatpickr/dist/flatpickr.css';
import 'react-toastify/dist/ReactToastify.css';

import { ContextProvider } from "./context/ContextProvider";
import IndexRouters from "./router"
import { ToastContainer } from 'react-toastify';
import { PermissionProvider } from "./context/PremissionContext"
import { useEffect } from "react"

function App() {

  useEffect(() => {
    // Prevent scroll on number inputs
    const preventScrollOnNumberInput = (e) => {
      if (e.target.type === "number") {
        e.preventDefault();
      }
    };

    // Attach the event listener to the window
    window.addEventListener("wheel", preventScrollOnNumberInput, { passive: false });

    // Cleanup the listener on unmount
    return () => {
      window.removeEventListener("wheel", preventScrollOnNumberInput);
    };
  }, []);

  return (
    <ContextProvider>
      <ToastContainer />
      <PermissionProvider>
        <div className="App">
          <IndexRouters />
        </div>
      </PermissionProvider>
    </ContextProvider>
  );
}

export default App;
