import React, { useState } from "react";
import { Row, Col, Image, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/base/Card";
import { useStateContext } from "../../context/ContextProvider";
import AuthenticationService from "../../services/auth.service";
import loginBackground from "../../assets/images/auth/login.jpg";
import companyLogo from "../../assets/images/auth/company-logo.jpg";
import { usePermissionContext } from "../../context/PremissionContext";

const SignIn = () => {
  const navigate = useNavigate();
  const { setCurrentUser, setUserToken, setIsLoading, showToast } = useStateContext();
  const { setPermissions } = usePermissionContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  function validateForm() {
    let isValid = true;

    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email format");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Password is required");
      isValid = false;
    } else if (password.length < 8) {
      setPasswordError("Password should have at least 8 characters");
      isValid = false;
    } else {
      setPasswordError("");
    }

    return isValid;
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      submitLogin();
    }
  };

  async function submitLogin() {
    setIsLoading(true);
    if (!validateForm()) {
      setIsLoading(false);
      return;
    }

    let item = { email, password };
    try {
      const response = await AuthenticationService.login(JSON.stringify(item));
      if (response.status === 201 && response.data.token) {
        setCurrentUser(response.data.user);
        setUserToken(response.data.token);
        const permissionsResponse = await AuthenticationService.getUserPermissions();
        if (permissionsResponse.status === 200) {
          setPermissions(permissionsResponse.data.data);
        }
        setIsLoading(false);
        navigate("/app/dashboard");
      } else {
        setIsLoading(false);
        showToast("Username or Password incorrect", "error");
      }
    } catch (error) {
      setIsLoading(false);
      showToast("Username or Password incorrect", "error");
    }
  }

  return (
    <>
      <section className="login-content">
        <Row className="m-0 align-items-center bg-white vh-100">
          <Col md="6">
            <Row className="justify-content-center">
              <Col md="10">
                <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                  <Card.Body>
                    {/* <Link
                      className="navbar-brand d-flex align-items-center mb-3"
                    >
                      <svg
                        width="30"
                        className="text-primary"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="-0.757324"
                          y="19.2427"
                          width="28"
                          height="4"
                          rx="2"
                          transform="rotate(-45 -0.757324 19.2427)"
                          fill="currentColor"
                        />
                        <rect
                          x="7.72803"
                          y="27.728"
                          width="28"
                          height="4"
                          rx="2"
                          transform="rotate(-45 7.72803 27.728)"
                          fill="currentColor"
                        />
                        <rect
                          x="10.5366"
                          y="16.3945"
                          width="16"
                          height="4"
                          rx="2"
                          transform="rotate(45 10.5366 16.3945)"
                          fill="currentColor"
                        />
                        <rect
                          x="10.5562"
                          y="-0.556152"
                          width="28"
                          height="4"
                          rx="2"
                          transform="rotate(45 10.5562 -0.556152)"
                          fill="currentColor"
                        />
                      </svg>
                    </Link> */}
                    <div className="text-center mb-4">
                      <Image src={companyLogo} alt="Company Logo" width={150} />
                    </div>
                    <h2 className="mb-2 text-center">Sign In</h2>
                    <Form onKeyPress={handleKeyPress}>
                      <Row>
                        <Col lg="12">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="email" className="">
                              Email
                            </Form.Label>
                            <Form.Control
                              type="email"
                              className=""
                              id="email"
                              aria-describedby="email"
                              placeholder="Enter your email"
                              onChange={(e) => setEmail(e.target.value)}
                              isInvalid={!!emailError}
                            />
                            <Form.Control.Feedback type="invalid">
                              {emailError}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg="12" className="">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="password" className="">
                              Password
                            </Form.Label>
                            <Form.Control
                              type="password"
                              className=""
                              id="password"
                              aria-describedby="password"
                              placeholder="Enter your password"
                              onChange={(e) => setPassword(e.target.value)}
                              isInvalid={!!passwordError}
                            />
                            <Form.Control.Feedback type="invalid">
                              {passwordError}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col
                          lg="12"
                          className="d-flex justify-content-between"
                        >
                          <Form.Check className="form-check mb-3">
                            <Form.Check.Input
                              type="checkbox"
                              id="customCheck1"
                            />
                            <Form.Check.Label htmlFor="customCheck1">
                              Remember Me
                            </Form.Check.Label>
                          </Form.Check>
                          <Link to="/auth/recoverpw">Forgot Password?</Link>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-center">
                        <Button
                          onClick={submitLogin}
                          type="button"
                          variant="btn btn-primary"
                        >
                          Sign In
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <div className="sign-bg">
              <svg
                width="280"
                height="230"
                viewBox="0 0 431 398"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.05">
                  <rect
                    x="-157.085"
                    y="193.773"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(-45 -157.085 193.773)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="7.46875"
                    y="358.327"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(-45 7.46875 358.327)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="61.9355"
                    y="138.545"
                    width="310.286"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(45 61.9355 138.545)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="62.3154"
                    y="-190.173"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(45 62.3154 -190.173)"
                    fill="#3B8AFF"
                  />
                </g>
              </svg>
            </div>
          </Col>
          <Col
            md="6"
            className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden"
          >
            <Image
              src={loginBackground}
              className="Image-fluid gradient-main animated-scaleX"
              alt="images"
            />
          </Col>
        </Row>
      </section>
    </>
  );
};

export default SignIn;
