import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../../context/ContextProvider";
import itemChallanDetailService from "../../../services/item-challan-detail.service";
import itemChallanService from "../../../services/item-challan.service";
import warehouseService from "../../../services/warehouse.service";
import challanDetailInStoreSchema from "./item-challan-detail-in-store-schema";
import challanDetailInUpdateSchema from "./item-challan-detail-in-update-schema";
import rawMaterialService from "../../../services/raw-material.service";
import Select from "react-select";

const AddItemChallanDetailIn = ({
  setShowAdd,
  showAdd,
  id,
  challan_id,
  challan_type,
  shift,
}) => {
  const navigate = useNavigate();
  const currentURL = window.location.href;

  const [challanType, setChallanType] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [item, setItem] = useState("");
  const [challanData, setChallanData] = useState();
  const [itemRemaining, setItemRemaining] = useState();
  const [isEntryInStore, setIsEntryInStore] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);

  const {
    setIsLoading,
    showToast,
    setRefereshData,
  } = useStateContext();

  // Error display helper
  const displayError = (error) => {
    let errArr = Object.keys(error).map((key) => error[key].join(","));
    errArr.map((column) => showToast(column, "error"));
  };

  // Fetch challan data
  const getChallanData = () => {
    itemChallanService
      .singleView(challan_id)
      .then((response) => {
        setChallanData(response.data.data);
      })
      .catch((error) => {
        displayError(error.response.data.errors);
      });
  };

  // Fetch list of items
  const getItemList = () => {
    rawMaterialService.lists()
      .then((response) => {
        setItemList(response.data.data);
      })
      .catch((error) => {
        displayError(error.response.data.errors);
      });
  };

  // Fetch list of warehouses
  const getWarehouseList = () => {
    warehouseService.lists()
      .then((response) => {
        setWarehouseList(response.data.data);
        setWarehouse(response.data.data[0]?.id || "");
      })
      .catch((error) => {
        displayError(error.response.data.errors);
      });
  };

  // Get item remaining quantity
  const itemAvailable = () => {
    setIsLoading(true);
    if (warehouse && item) {
      let values = {
        warehouse_id: warehouse,
        item_id: item,
      };
      itemChallanDetailService
        .itemChallanInList(values)
        .then((response) => {
          setItemRemaining(response.data.data);
          formik.setFieldValue("quantity", response.data.data[0].quantity_difference);
          setIsLoading(false);
        })
        .catch((error) => {
          displayError(error.response.data.errors);
          setIsLoading(false);
        });
    } else {
      showToast("Please select both warehouse and item", "error");
      setIsLoading(false);
    }
  };

  // Store the form values
  const store = (values) => {
    setIsLoading(true);
    const updatedValues = {
      ...values,
      warehouse_id: warehouse,
      date: challanData.challan_date,
      type: challanData.challan_type,
      to_id: challanData.to_id,
      to_name: challanData.to_name,
      challan_id: challan_id,
      shift: shift,
      item_id: item,
    };
    itemChallanDetailService
      .storeChallanIn(updatedValues)
      .then(() => {
        setIsLoading(false);
        setShowAdd(false);
        setRefereshData(true);
        showToast("Item added.", "success");
      })
      .catch((error) => {
        setIsLoading(false);
        displayError(error.response.data.errors);
      });
  };

  const submitForm = (values) => {
    store(values);
  };

  const setInitialData = () => {
    formik.setValues({
      item_id: "",
      quantity: "",
      item_status: "damaged",
      created_by: "",
      updated_by: "",
    });
  };

  useEffect(() => {
    getChallanData();
    getItemList();
    getWarehouseList();
    setInitialData();
  }, [challan_type]);

  const formik = useFormik({
    initialValues: {
      item_id: "",
      quantity: "",
      item_status: "",
      created_by: "",
      updated_by: "",
    },
    validationSchema: id ? challanDetailInUpdateSchema : challanDetailInStoreSchema,
    onSubmit: submitForm,
  });

  const itemOptions = itemList.map((item) => ({
    value: item.id,
    label: item.text,
  }));

  return (
    <Container>
      <Offcanvas
        show={showAdd}
        onHide={() => setShowAdd(false)}
        placement="end"
        className="overflow-auto"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>
            <h4>Add Item In Detail</h4>
          </Offcanvas.Title>
          <button
            type="button"
            className="btn-close"
            onClick={() => setShowAdd(false)}
            aria-label="Close"
          ></button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md="12">
                <Form.Label className="h6">Item Name</Form.Label>
                <Select
                  options={itemOptions}
                  value={itemOptions.find((option) => option.value === item)}
                  onChange={(selectedOption) => {
                    setItem(selectedOption.value);
                    formik.setFieldValue("item_id", selectedOption.value);
                  }}
                  onBlur={formik.handleBlur}
                  className={`form-control p-0`}
                />
              </Col>
              <Col md="12">
                <Form.Label className="h6">Quantity</Form.Label>
                <Form.Control
                  type="number"
                  name="quantity"
                  value={formik.values.quantity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.quantity && !!formik.errors.quantity}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.quantity}
                </Form.Control.Feedback>
              </Col>
              <Col md="12">
                <Form.Label className="h6">Status</Form.Label>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Return to WAREHOUSE"
                  checked={isEntryInStore}
                  onChange={() => setIsEntryInStore(!isEntryInStore)}
                />
                <Form.Select
                  name="item_status"
                  value={formik.values.item_status}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.item_status && !!formik.errors.item_status}
                >
                  <option value="">Select the status</option>
                  {isEntryInStore ? (
                    <>
                      <option value="fresh">Fresh</option>
                      <option value="reusable">Reusable</option>
                      <option value="repairable">Repairable</option>
                    </>
                  ) : (
                    <option value="damaged">Damaged</option>
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.item_status}
                </Form.Control.Feedback>
              </Col>
              <p className="mt-2">
                <strong>NOTE: If item is returned to store toggle ON, return to WAREHOUSE.</strong>
              </p>
            </Row>
          </Form>
        </Offcanvas.Body>
        <div className="col-12 d-flex flex-column gap-1 bottom-0">
          <Button className="m-3 mb-0" variant="outline-secondary" type="submit" onClick={formik.handleSubmit}>
            {id !== undefined ? "Update" : "Save"}
          </Button>
          <Button className="m-3 mb-0" variant="outline-warning" onClick={setInitialData}>
            Reset Data
          </Button>
          <Button className="m-3 mb-0" variant="outline-secondary" onClick={() => setShowAdd(false)}>
            Cancel
          </Button>
        </div>
      </Offcanvas>
    </Container>
  );
};

export default AddItemChallanDetailIn;
