import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "../../context/ContextProvider";
import attributeService from "../../services/attribute.service";
import stockCloseService from "../../services/stock-close.service";
import stockCloseUpdateSchema from "./stock-close-update-schema";
import stockCloseStoreSchema from "./stock-close-store-schema";
import StockCloseDetail from "./stock-close-detail/stock-close-detail";
import OpStockCloseDetail from "./op-stock-close-detail";

const OpAddStockClose = () => {
    const navigate = useNavigate();
    const { id, type } = useParams();
    const { setIsLoading, setPageTitle, showToast } = useStateContext();

    const now = new Date();
    const kathmanduTimeOptions = { timeZone: 'Asia/Kathmandu', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
    const todayKathmandu = new Date(now.toLocaleString('en-US', { timeZone: 'Asia/Kathmandu' }));
    const today = todayKathmandu.toISOString().split('T')[0];

    const yesterdayKathmandu = new Date(todayKathmandu);
    yesterdayKathmandu.setDate(todayKathmandu.getDate() - 1);
    const yesterday = yesterdayKathmandu.toISOString().split('T')[0];

    const data = {
        shift: "",
        close_type: "Daily",
        entry_date: today,
        status: "Active",
        created_by: "",
        updated_by: "",
    };

    const displayError = (error) => {
        let errArr = [];
        errArr = Object.keys(error).map((key) => {
            return error[key].join(",");
        });
        errArr.map((column) =>
            showToast(column, "error")
        );
    }

    const store = (values) => {
        setIsLoading(true);
        const type = values.shift === "Morning" ? 'mor' : values.shift === "Evening" ? 'even' : "";
        stockCloseService
            .store(values, type)
            .then((response) => {
                setIsLoading(false);
                navigate(`/app/stock_close/${type}/edit/${response.data.data.close_id}`);
                showToast(`New Stock Close added.`, "success");
            })
            .catch((error) => {
                setIsLoading(false);
                displayError(error.response.data.errors);
            });
    };

    const update = (values) => {
        setIsLoading(true);
        stockCloseService
            .update(id, values, type)
            .then((response) => {
                setIsLoading(false);
                showToast(`Stock Close updated.`, "success");
            })
            .catch((error) => {
                setIsLoading(false);
                displayError(error.response.data.errors);
            });
    };

    const submitForm = (values) => {
        id === undefined || id === "0" ? store(values) : update(values);
    };

    const [closeType, setCloseType] = useState();
    const [shift, setShift] = useState();

    const getCloseType = () => {
        attributeService.childrenList("Close Type")
            .then((response) => {
                setCloseType(response.data);
            })
            .catch((error) => {
                displayError(error.response.data.errors);
            })
        attributeService.childrenList("Shift")
            .then((response) => {
                setShift(response.data);
            })
            .catch((error) => {
                displayError(error.response.data.errors);
            })
    }

    useEffect(() => {
        setPageTitle("Stock Closing");
        getCloseType();
    }, [setPageTitle]);

    const formik = useFormik({
        initialValues: data,
        validationSchema: id !== undefined ? stockCloseUpdateSchema : stockCloseStoreSchema,
        onSubmit: submitForm,
    });

    return (
        <Container>
            <Card>
                <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">
                            {id ? "Edit Stock Closing" : "Add Stock Closing"}
                        </h4>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        <Row className="mb-3">
                            <h5>Basic Information</h5>
                            <hr className="my-3" />
                            <Col md="6" className="p-2">
                                <Form.Label htmlFor="validationDefault041">
                                    Shift
                                </Form.Label>
                                <Form.Select
                                    className="form-control form-select"
                                    name="shift"
                                    value={formik.values.shift}
                                    onChange={(e) => {
                                        formik.setFieldValue('shift', e.target.value);
                                    }}
                                    onBlur={formik.handleBlur}
                                    isInvalid={
                                        formik.touched.shift && !!formik.errors.shift
                                    }
                                >
                                    <option value="">Select the shift</option>
                                    {shift ? (
                                        <>
                                            {
                                                shift.map((list) => (
                                                    <option key={list.id} value={list.text}>{list.text}</option>
                                                ))
                                            }
                                        </>
                                    ) :
                                        null
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.shift}
                                </Form.Control.Feedback>
                            </Col>
                            <Col md="6" className="p-2">
                                <Form.Label htmlFor="validationDefault041">
                                    Close Type
                                </Form.Label>
                                <Form.Select
                                    className="form-control form-select"
                                    name="close_type"
                                    value={formik.values.close_type}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={
                                        formik.touched.close_type && !!formik.errors.close_type
                                    }
                                >
                                    <option value="">Select the close type</option>
                                    {closeType ? (
                                        <>
                                            {
                                                closeType.map((list) => (
                                                    <option key={list.id} value={list.text}>{list.text}</option>
                                                ))
                                            }
                                        </>
                                    ) :
                                        null
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.close_type}
                                </Form.Control.Feedback>
                            </Col>
                            <Col md="6">
                                <Form.Label htmlFor="validationCustom01">
                                    Entry Date
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    name="entry_date"
                                    value={formik.values.entry_date}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    max={today} // Setting max date to today's date
                                    min={yesterday} // Setting min date to yesterday's date
                                    isInvalid={
                                        formik.touched.entry_date &&
                                        !!formik.errors.entry_date
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.entry_date}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            {formik.isValid && formik.touched ? (
                <Card>
                    <Card.Body>
                        <OpStockCloseDetail close_data={formik.values} />
                    </Card.Body>
                </Card>
            ) : null}
        </Container>
    );
};

export default OpAddStockClose;
