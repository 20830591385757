import { useFormik } from "formik";
import React, { useEffect, useState, Fragment } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { useStateContext } from "../../context/ContextProvider";
import { usePermissionContext } from "../../context/PremissionContext";
import purchaseEntryService from "../../services/purchase-entry.service";
import purchaseEntryDetailService from "../../services/purchase-entry-detail.service";
import purchaseEntryUpdateSchema from "./purchase-entry-update-schema";
import purchaseEntryStoreSchema from "./purchase-entry-store-schema";
import vendorService from "../../services/vendor.service";
import warehouseService from "../../services/warehouse.service";
import rawMaterialService from "../../services/raw-material.service";
import { AiFillDelete, AiFillPlusCircle } from "react-icons/ai";

const OpAddPurchaseEntry = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setIsLoading, setPageTitle, showToast } = useStateContext();

  const initialData = {
    warehouse_id: "",
    invoice_no: "",
    challan_no: "",
    invoice_date: "",
    vendor_id: "",
    entry_date: "",
    bill_file: "",
    created_by: "",
    updated_by: "",
  };

  const displayError = (error) => {
    let errArr = Object.keys(error).map((key) => error[key].join(","));
    errArr.forEach((column) => showToast(column, "error"));
  };

  const [billFile, setBillFile] = useState();
  const [vendorList, setVendorList] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const [forms, setForms] = useState([
    {
      item_id: "",
      unit: "",
      quantity: "",
      rate: "",
      amount: "",
      vat_applicable: ""
    },
  ]);

  const store = (values) => {
    setIsLoading(true);
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });
    if (billFile) {
      formData.append("bill_file", billFile);
    }
    purchaseEntryService
      .store(formData)
      .then((response) => {
        setIsLoading(false);
        navigate(`/app/store/purchase_entry/edit/${response.data.data.entry_id}`);
        showToast(`New Purchase Entry added.`, "success");
      })
      .catch((error) => {
        setIsLoading(false);
        displayError(error.response.data.errors);
      });
  };

  const update = (values) => {
    setIsLoading(true);
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });
    if (billFile) {
      formData.append("bill_file", billFile);
    }
    purchaseEntryService
      .update(id, formData)
      .then((response) => {
        setIsLoading(false);
        navigate(`/app/store/purchase_entry/edit/${response.data.data.entry_id}`);
        showToast(`Purchase Entry updated.`, "success");
      })
      .catch((error) => {
        setIsLoading(false);
        displayError(error.response.data.errors);
      });
  };

  const submitForm = (values) => {
    id === undefined || id === "0" ? store(values) : update(values);
  };

  const setInitialData = () => {
    if (id !== undefined) {
      setIsLoading(true);
      purchaseEntryService
        .singleView(id)
        .then((response) => {
          if (response.status === 200) {
            formik.setValues(response.data.data);
          }
        })
        .catch((error) => {
          showToast(error.response.data.message, "error");
          navigate("/app/purchase_entry");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      formik.setValues(initialData);
    }
  };

  const getVendorList = () => {
    vendorService
      .lists()
      .then((response) => {
        setVendorList(response.data.data);
      })
      .catch((error) => {
        displayError(error.response.data.errors);
      });
  };

  const getWarehouseList = () => {
    warehouseService
      .lists()
      .then((response) => {
        setWarehouseList(response.data.data);
      })
      .catch((error) => {
        displayError(error.response.data.errors);
      });
  };

  const [itemList, setItemList] = useState([]);

  const getItemList = () => {
    rawMaterialService
      .lists()
      .then((response) => {
        setItemList(response.data.data);
      })
      .catch((error) => {
        displayError(error.response.data.errors);
      });
  };

  useEffect(() => {
    setPageTitle("Purchase Entry");
    setInitialData();
    getVendorList();
    getWarehouseList();
    getItemList();
  }, [id, setPageTitle]);

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: id !== undefined ? purchaseEntryUpdateSchema : purchaseEntryStoreSchema,
    onSubmit: submitForm,
  });

  const handleSubmitAll = () => {
    setIsLoading(true);
    if (forms.length > 0) {
      const formData = new FormData();
      Object.keys(formik.values).forEach((key) => {
        formData.append(key, formik.values[key]);
      });
      if (billFile) {
        formData.append("bill_file", billFile);
      }
      formData.append("items", JSON.stringify(forms));
      console.log(formData);
      purchaseEntryService
        .storeAll(formData)
        .then((response) => {
          setIsLoading(false);
          navigate(`/app/purchase_entry`);
          showToast(`New Purchase Entry added.`, "success");
        })
        .catch((error) => {
          setIsLoading(false);
          displayError(error.response.data.errors);
        });
    } else {
      setIsLoading(false);
      showToast("No data to submit", "warning");
    }
  };

  const addForm = () => {
    setForms([
      ...forms,
      {
        item_id: "",
        unit: "",
        quantity: "",
        rate: "",
        amount: "",
        vat_applicable: ""
      },
    ]);
  };

  const removeForm = (index) => {
    setForms(forms.filter((_, i) => i !== index));
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newForms = [...forms];
    newForms[index] = { ...newForms[index], [name]: value };

    if (name === "quantity" || name === "rate") {
      newForms[index].amount = calculateAmount(newForms[index].quantity, newForms[index].rate, newForms[index].vat, newForms[index].vat_applicable);
    }

    setForms(newForms);
  };

  const calculateAmount = (quantity, rate, vat, vatApplicable) => {
    let amount = quantity * rate;

    //TODO changed for time being
    if (vatApplicable === "Yes") {
      amount += (amount * vat) / 100;
    }
    return amount;
  };

  return (
    <Container>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">
              {id ? "Edit Purchase Entry" : "Add Purchase Entry"}
            </h4>
          </div>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Row className="mb-3">
              <h5>Basic Information</h5>
              <hr className="my-3" />
              <Col md="6" className="p-2">
                <Form.Label htmlFor="warehouse_id">Warehouse Name</Form.Label>
                <Form.Select
                  id="warehouse_id"
                  name="warehouse_id"
                  value={formik.values.warehouse_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.warehouse_id && !!formik.errors.warehouse_id}
                >
                  <option value="">Select the warehouse</option>
                  {warehouseList.map((list) => (
                    <option key={list.id} value={list.id}>{list.text}</option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.warehouse_id}
                </Form.Control.Feedback>
              </Col>
              <Col md="6" className="p-2">
                <Form.Label htmlFor="invoice_no">Invoice Number</Form.Label>
                <Form.Control
                  type="text"
                  id="invoice_no"
                  name="invoice_no"
                  value={formik.values.invoice_no}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.invoice_no && !!formik.errors.invoice_no}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.invoice_no}
                </Form.Control.Feedback>
              </Col>
              <Col md="6" className="p-2">
                <Form.Label htmlFor="challan_no">Challan Number</Form.Label>
                <Form.Control
                  type="text"
                  id="challan_no"
                  name="challan_no"
                  value={formik.values.challan_no}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.challan_no && !!formik.errors.challan_no}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.challan_no}
                </Form.Control.Feedback>
              </Col>
              <Col md="6" className="p-2">
                <Form.Label htmlFor="invoice_date">Invoice Date</Form.Label>
                <Form.Control
                  type="date"
                  id="invoice_date"
                  name="invoice_date"
                  value={formik.values.invoice_date}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.invoice_date && !!formik.errors.invoice_date}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.invoice_date}
                </Form.Control.Feedback>
              </Col>
              <Col md="6" className="p-2">
                <Form.Label htmlFor="vendor_id">Vendor Name</Form.Label>
                <Form.Select
                  id="vendor_id"
                  name="vendor_id"
                  value={formik.values.vendor_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.vendor_id && !!formik.errors.vendor_id}
                >
                  <option value="">Select the vendor name</option>
                  {vendorList.map((list) => (
                    <option key={list.id} value={list.id}>{list.text}</option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.vendor_id}
                </Form.Control.Feedback>
              </Col>
              <Col md="6" className="p-2">
                <Form.Label htmlFor="bill_file">Upload Bill Photo</Form.Label>
                <Form.Control
                  type="file"
                  id="bill_file"
                  name="bill_file"
                  onChange={(e) => {
                    formik.setFieldValue('bill_file', e.target.files[0]);
                    setBillFile(e.target.files[0]);
                  }}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.bill_file && !!formik.errors.bill_file}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.bill_file}
                </Form.Control.Feedback>
              </Col>
            </Row>
            {formik.isValid && formik.values
              ? (
                <Row className="mb-3">
                  <h5>Item Details</h5>
                  <hr className="my-3" />
                  {forms.map((form, index) => (
                    <Fragment key={index}>
                      <Row>
                        <Col md="4" className="p-2">
                          <Form.Label htmlFor={`item_id_${index}`} className="h6">
                            Item Name
                          </Form.Label>
                          <Select
                            id={`item_id_${index}`}
                            name="item_id"
                            options={itemList.map((item, idx) => ({
                              value: item.id,
                              label: `${idx + 1}. ${item.text}`,
                              unit: item.unit,
                              vat_applicable: item.vat_applicable,
                            }))}
                            value={itemList.find(
                              (option) => option.value === form.item_id
                            )}
                            onChange={(selectedOption) => {
                              const newForms = [...forms];
                              newForms[index].item_id = selectedOption.value;
                              newForms[index].unit = selectedOption.unit;
                              newForms[index].vat_applicable = selectedOption.vat_applicable;
                              newForms[index].vat = selectedOption.vat_applicable === "Yes" ? 13 : 0;
                              newForms[index].amount = calculateAmount(newForms[index].quantity, newForms[index].rate, newForms[index].vat, newForms[index].vat_applicable);
                              setForms(newForms);
                            }}
                          />
                        </Col>
                        <Col md="2" className="p-2">
                          <Form.Label htmlFor={`unit-${index}`}>Unit</Form.Label>
                          <Form.Control
                            type="text"
                            id={`unit-${index}`}
                            name="unit"
                            value={form.unit}
                            onChange={(e) => handleChange(index, e)}
                          />
                        </Col>
                        <Col md="2" className="p-2">
                          <Form.Label htmlFor={`quantity-${index}`}>Quantity</Form.Label>
                          <Form.Control
                            type="number"
                            id={`quantity-${index}`}
                            name="quantity"
                            value={form.quantity}
                            onChange={(e) => handleChange(index, e)}
                          />
                        </Col>
                        <Col md="2" className="p-2">
                          <Form.Label htmlFor={`rate-${index}`}>Rate</Form.Label>
                          <Form.Control
                            type="number"
                            id={`rate-${index}`}
                            name="rate"
                            value={form.rate}
                            onChange={(e) => handleChange(index, e)}
                          />
                        </Col>
                        <Col md="2" className="p-2">
                          <Form.Label htmlFor={`amount-${index}`}>Amount</Form.Label>
                          <Form.Control
                            type="number"
                            id={`amount-${index}`}
                            name="amount"
                            value={form.amount}
                            readOnly
                          />
                        </Col>
                        <Col md="2" className="p-2">
                          <Form.Label htmlFor={`vat_applicable-${index}`}>VAT Applicable</Form.Label>
                          <Form.Select
                            disabled
                            id={`vat_applicable-${index}`}
                            name="vat_applicable"
                            value={form.vat_applicable}
                            onChange={(e) => handleChange(index, e)}
                          >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </Form.Select>
                        </Col>
                        <Col className="d-flex justify-content-end p-2 m-3 gap-2">
                          <Button variant="outline-danger" className="d-flex p-2" onClick={() => removeForm(index)}>
                            <AiFillDelete style={{ fontSize: "1.3rem" }} />
                          </Button>
                          <Button onClick={addForm} variant="btn btn-outline-primary">
                            <AiFillPlusCircle /> Add Item
                          </Button>
                        </Col>
                      </Row>
                    </Fragment>
                  ))}
                  <Row className="mt-4">
                    <Col md="4">
                      <h6>Total Taxable Amount:</h6>
                      <p>
                        {forms
                          .reduce((total, form) => {
                            const amountWithoutVAT = form.vat_applicable === "Yes" ? form.amount / 1.13 : form.amount;
                            return total + (amountWithoutVAT || 0); // Default to 0 if undefined
                          }, 0)
                          .toFixed(2)}
                      </p>
                    </Col>
                    <Col md="4">
                      <h6>Total VAT Amount:</h6>
                      <p>
                        {forms
                          .reduce((total, form) => {
                            const vatAmount = form.vat_applicable === "Yes" ? form.amount * 0.13 / 1.13 : 0;
                            return total + (vatAmount || 0); // Default to 0 if undefined
                          }, 0)
                          .toFixed(2)}
                      </p>
                    </Col>
                    <Col md="4">
                      <h6>Total Amount (Including VAT):</h6>
                      <p>
                        {forms.reduce((total, form) => total + (form.amount || 0), 0).toFixed(2)}
                      </p>
                    </Col>
                  </Row>
                  <Col md="12" className="d-flex justify-content-end gap-2">
                    <Button type="button" className="btn btn-primary" onClick={handleSubmitAll}>
                      Submit All Data
                    </Button>
                  </Col>
                </Row>
              )
              :
              <></>
            }
          </Form>
        </Card.Body>
      </Card>
    </Container >
  );
};

export default OpAddPurchaseEntry;
