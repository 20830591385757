import axios from "axios";
import { baseUrl } from "./base-url";
import { useNavigate } from "react-router-dom";

const axiosClient = axios.create({
    baseURL: `${baseUrl}/api`,
    // baseURL: 'http://localhost:8000/api',
    //this code is updated
    mode: 'no-cors',
    headers: {
        "Content-type": "application/json",
        "Accept": "application/json"
    }
});

axiosClient.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    return config
});

axiosClient.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response && error.response.status === 401) {
        localStorage.removeItem('token')
        const navigate = useNavigate();
        navigate('/');
        return error;
    }
    throw error;
})

export default axiosClient;