import axiosClient from "../axios";

class ItemChallanDetailService {
    lists() {
        return axiosClient.get('/item_challans/details/list');
    }
    approvals(values) {
        return axiosClient.post('/item_challans/details/approvals', values);
    }
    //to get all data
    view(page, rowsPerPage) {
        return axiosClient.get(`/item_challans/details?page=${page}&pp=${rowsPerPage}`);
    }
    //to store data
    store(values) {
        return axiosClient.post("/item_challans/details", values);
    }
    //to store data
    storeChallanOut(values) {
        return axiosClient.post("/item_challans/details/out", values);
    }
    //to store data
    storeChallanIn(values) {
        return axiosClient.post("/item_challans/details/in", values);
    }
    // to get the item available
    itemList(values) {
        return axiosClient.post("/item_challans/out/item_list", values);
    }
    //to get batch_id and the quantity available for item challan in
    itemChallanInList(values) {
        return axiosClient.post("/item_challans/in/item_list", values);
    }
    //to update data
    update(uuid, values) {
        return axiosClient.put(`/item_challans/details/${uuid}`, values);
    }
    // to simple search 
    search(url) {
        return axiosClient.get(`/item_challans/details?${url}`);
    }
    //to search single data
    singleView(uuid) {
        return axiosClient.get(`/item_challans/details/${uuid}`);
    }
    //to delete single data
    delete(uuid) {
        return axiosClient.delete("/item_challans/details/" + uuid, { detail_id: uuid });
    }
    //to restore single data
    restore(uuid) {
        return axiosClient.delete(`/item_challans/details/${uuid}/restore`);
    }
    //to prune single data
    prune(uuid) {
        return axiosClient.delete(`/item_challans/details/${uuid}/prune`);
    }
    //to delete selected row data
    deleteAll(selectedRowsUid) {
        return axiosClient.post("/item_challans/details/deleteall", { 'ids': selectedRowsUid });
    }
    //to restore selected row data
    restoreAll(selectedRowsUUID) {
        return axiosClient.post("/item_challans/details/restoreall", { 'ids': selectedRowsUUID });
    }
    //to prune selected row data
    pruneAll(selectedRowsUUID) {
        return axiosClient.post("/item_challans/details/pruneall", { 'ids': selectedRowsUUID });
    }
    //to get deleted data
    viewDeleted() {
        return axiosClient.get("/item_challans/details");
    }


}
export default new ItemChallanDetailService();