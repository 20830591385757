import React, { memo, Fragment, useState, useEffect } from "react";
import {
  AiFillFunnelPlot,
} from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import { Col, Card, Button, Form, Row } from "react-bootstrap";
//components
import { useStateContext } from "../../context/ContextProvider";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import imsReportService from "../../services/ims-report.service";
import Select from 'react-select';

const PurchaseFilter = memo(() => {
  const navigate = useNavigate();
  const {
    dtColorMode,
    showToast,
    setPageTitle,
    setIsLoading,
    refereshData,
    setRefereshData,
  } = useStateContext();

  // Data for the datatable
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  // Datatable columns
  const tableColumns = React.useMemo(
    () => [
      {
        id: "sno",
        name: (
          <span>
            S.N </span>),
        cell: (row, index) => index + 1,
        minWidth: '50px',
        maxWidth: '120px'
      },
      {
        id: "invoice_date",
        name: (
          <span>
            Invoice Date</span>),
        selector: (row) => row.invoice_date,
        sortable: true,
        wrap: true
      },
      {
        id: "vendor_name",
        name:
          (<span>Vendor Name</span>),
        selector: (row) => row.vendor_name,
        sortable: true,
        grow: 2,
      },
      {
        id: "raw_material_name",
        name: (
          <span>Item Name
          </span>
        ),
        selector: (row) => row.raw_material_name,
        sortable: true,
        grow: 2,
      },
      {
        id: "quantity",
        name: (
          <span>
            Quantity</span>),
        selector: (row) => row.quantity,
        sortable: true,
      },
      {
        id: "rate",
        name: (<span>Rate</span>),
        selector: (row) => row.rate,
        sortable: true,
      },
      {
        id: "amount",
        name: (
          <span>
            Amount</span>),
        selector: (row) => row.amount,
        sortable: true,
      },
    ],
    [data]
  );

  const getDataList = (page, rowsPerPage) => {
    setIsLoading(true);
    imsReportService
      .purchase_filter()
      .then((response) => {
        const fetchedData = response.data.data;
        setData(fetchedData);
        setIsLoading(false);
      })
      .catch((error) => {
        showToast(error.response.data.message, "error");
        setIsLoading(false);
      });
  };

  const handleExport = () => {
    setIsLoading(true);
    showToast("Data exported successfully", "success");
    setIsLoading(false);
  };

  const [shift, setShift] = useState("");
  const [entryDate, setEntryDate] = useState("");

  const handleShiftChange = (e) => {
    setShift(e.target.value);
  };

  const handleEntryDateChange = (e) => {
    setEntryDate(e.target.value);
  };

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [vendor, setVendor] = useState();
  const [item, setItem] = useState();

  const [itemList, setItemList] = useState();
  const [vendorList, setVendorList] = useState();

  const getInitialList = () => {
    imsReportService.purchase_filter_initial()
      .then((response) => {
        setItemList(response.data.data.raw_materials);
        setVendorList(response.data.data.vendors);
      })
  }
  const raw_materials = itemList?.map((item) => ({
    value: item.id,
    label: item.text,
  })) || [];

  const vendors = vendorList?.map((vendor) => ({
    value: vendor.id,
    label: vendor.text,
  })) || [];

  const handleSubmit = (e) => {

    e.preventDefault();
    setIsLoading(true);
    imsReportService
      .purchase_filter({
        from_date: fromDate,
        to_date: toDate,
        vendor_id: vendor,
        item_id: item
      })
      .then((response) => {
        const fetchedData = response.data.data;
        setData(fetchedData);
        setIsLoading(false);
      })
      .catch((error) => {
        showToast(error.response.data.message, "error");
        setIsLoading(false);
      });
    // Here you would handle form submission and pass the values to your backend or API call
    console.log({ fromDate, toDate, vendor, item });
  };

  useEffect(() => {
    getInitialList();
    setPageTitle("Purchase Filter");
  }, [currentPage, perPage, refereshData]);

  return (
    <>
      <Fragment>
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">Inventory</h4>
            </div>
          </Card.Header>
          <Card.Body>
            <div>
              <Form onSubmit={handleSubmit}>
                <div>
                  <Row>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>From Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={fromDate}
                          onChange={(e) => setFromDate(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>To Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={toDate}
                          onChange={(e) => setToDate(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>Vendor</Form.Label>
                        <Select
                          options={vendors}
                          value={vendors.find(v => v.value === vendor) || null}
                          onChange={(selectedOption) => setVendor(selectedOption ? selectedOption.value : null)}
                          isClearable
                        />
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>Item</Form.Label>
                        <Select
                          options={raw_materials}
                          value={raw_materials.find(i => i.value === item) || null}
                          onChange={(selectedOption) => setItem(selectedOption ? selectedOption.value : null)}
                          isClearable
                        />
                      </Form.Group>
                    </Col>
                    <Col className="mt-4">
                      <Button type="submit">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            </div>
            <div>
              <DataTable
                className="data-table"
                columns={tableColumns}
                data={data}
                pagination
                paginationServer
                paginationPerPage={perPage}
                paginationComponentOptions={{
                  selectAllRowsItem: true,
                  selectAllRowsItemText: "All",
                }}
                paginationRowsPerPageOptions={[10, 20, 30, 50]}
                paginationServerPage={currentPage}
                paginationTotalRows={totalRows}
                onChangePage={(page) => {
                  setCurrentPage(page);
                }}
                onChangeRowsPerPage={(newPerPage, page) => {
                  setPerPage(newPerPage === "All" ? data.length : newPerPage);
                  setCurrentPage(page);
                }}
                theme={dtColorMode == "dark" ? "dark" : ""}
                selectableRowsHighlight
                highlightOnHover
                fixedHeader
                subHeader
                subHeaderComponent={
                  <>
                  </>
                }
              />
            </div>
          </Card.Body>
        </Card>
      </Fragment >
    </>
  );
});

PurchaseFilter.displayName = "PurchaseFilter";
export default PurchaseFilter;
