import React, { memo, Fragment, useState, useEffect, useRef } from "react";
import { BsDatabaseFillX } from "react-icons/bs";
import { AiFillDelete, AiFillPlusCircle, AiFillFunnelPlot } from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import { LuDatabaseBackup } from "react-icons/lu";
// sweetalert
import Swal from "sweetalert2";
//react-bootstrap
import { HiMiniViewfinderCircle } from "react-icons/hi2";
import { Col, Card, Button, Form } from "react-bootstrap";
//components
import { useStateContext } from "../../context/ContextProvider";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";

import mistakeTicketService from "../../services/mistake-ticket.service";
import TableIcons from "../../components/TableIcons";
import MistakeTicketFilter from "./mistake-ticket-filter";
import { usePermissionContext } from "../../context/PremissionContext";

const MistakeTicket = memo(() => {
  const navigate = useNavigate();

  const { permissions } = usePermissionContext();

  const createPermission = Array.isArray(permissions) && permissions.some(permission => permission.name === 'Create Stock Mistake');
  const updatePermission = Array.isArray(permissions) && permissions.some(permission => permission.name === 'Update Stock Mistake');
  const viewPermission = Array.isArray(permissions) && permissions.some(permission => permission.name === 'View Stock Mistake');
  const deletePermission = Array.isArray(permissions) && permissions.some(permission => permission.name === 'Delete Stock Mistake');
  const restorePermission = Array.isArray(permissions) && permissions.some(permission => permission.name === 'Restore Stock Mistake');
  const purgePermission = Array.isArray(permissions) && permissions.some(permission => permission.name === 'Purge Stock Mistake');

  const { dtColorMode, showToast, setPageTitle, setIsLoading } = useStateContext();
  // Filter parameters state
  const [searchFilter, setSearchFilter] = useState(
    JSON.parse(sessionStorage.getItem("mistake_ticket_filter")) || {
      ticket_number: "",
      mistake_type: [],
      pervious_data: "",
      current_data: "",
      remarks: "",
      data_date: "",
      entry_date: "",
      approval_date: "",
      approval_status: [],
      status: [],
      dataStatus: "Only Live",
      searchKey: "",
    }
  );
  const filterQueryMapping = {
    ticket_number: "like",
    mistake_type: "in",
    pervious_data: "like",
    current_data: "like",
    remarks: "like",
    data_date: "like",
    entry_date: "like",
    approval_date: "like",
    status: "in",
    approval_status: "in",
    dataStatus: "is_del",
    searchKey: "",
  };

  // Data for the datatable
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [searchType, setSearchType] = useState([]);
  // State to manage the show/hide columns
  const [hideColumns, setHideColumns] = useState({
    ticket_number: false,
    mistake_type: false,
    pervious_data: false,
    current_data: false,
    remarks: false,
    data_date: false,
    entry_date: false,
    approval_date: false,
    approved_by: false,
    approval_status: false,
    status: false,
    actions: false,
  });

  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const handleOffcanvasToggle = () => {
    setShowOffcanvas(!showOffcanvas);
  }

  // Datatable columns
  const tableColumns = React.useMemo(
    () => [
      {
        id: "sno",
        name: "SNo",
        cell: (row, index) => index + 1,
      },
      {
        id: "ticket_number",
        name: "Ticket Number",
        selector: (row) => row.ticket_number,
        sortable: true,
        omit: hideColumns.ticket_number,
        wrap: true,
      },
      {
        id: "mistake_type",
        name: "Mistake Type",
        selector: (row) => row.mistake_type,
        sortable: true,
        omit: hideColumns.mistake_type,
        wrap: true,
      },
      {
        id: "pervious_data",
        name: "Pervious Data",
        selector: (row) => row.pervious_data,
        sortable: true,
        omit: hideColumns.pervious_data,
        wrap: true,
      },
      {
        id: "current_data",
        name: "Current Data",
        selector: (row) => row.current_data,
        sortable: true,
        omit: hideColumns.current_data,
        wrap: true,
      },
      {
        id: "remarks",
        name: "Remarks",
        selector: (row) => row.remarks,
        sortable: true,
        omit: hideColumns.remarks,
        wrap: true,
      },
      {
        id: "data_date",
        name: "Data Date",
        selector: (row) => row.data_date,
        sortable: true,
        omit: hideColumns.data_date,
        wrap: true,
      },
      {
        id: "entry_date",
        name: "Entry Date",
        selector: (row) => row.entry_date,
        sortable: true,
        omit: hideColumns.entry_date,
        wrap: true,
      },
      {
        id: "approval_date",
        name: "Approval Date",
        selector: (row) => row.approval_date,
        sortable: true,
        omit: hideColumns.approval_date,
        wrap: true,
      },
      {
        id: "approval_status",
        name: "Approval Status",
        wrap: true,
        selector: (row) => (
          <span
            className={`badge rounded-pill bg-${row.approval_status === "Pending" ? "warning" : row.approval_status === "Done" ? "success" : "danger"
              } p-2 text-data.statusColor`}
          >
            {row.approval_status}
          </span>
        ),
        sortable: true,
        omit: hideColumns.approval_status,
      },
      {
        id: "status",
        name: "Status",
        wrap: true,
        selector: (row) => row.status,
        sortable: true,
        omit: hideColumns.status,
        cell: (row) => (
          <span
            className={`badge rounded-pill bg-${row.status === "Active" ? "success" : "danger"
              } p-2 text-data.statusColor`}
          >
            {row.status}
          </span>
        ),
      },
      {
        id: "actions",
        name: "Actions",
        grow: 2,
        cell: (row) => (
          <div className="flex align-items-center list-user-action">
            {!row.deleted_at ? (
              <>
                {updatePermission && (
                  <a
                    className="btn btn-sm btn-icon btn-primary mr-2"
                    style={{ marginRight: "5px" }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="Add"
                    to="#"
                    onClick={() => handleEdit(row.entry_id)}
                  >
                    <span className="btn-inner">{TableIcons.edit()}</span>
                  </a>
                )}
                {deletePermission && (
                  <a
                    className="btn btn-sm btn-icon btn-danger mr-2"
                    style={{ marginRight: "5px" }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="Add"
                    to="#"
                    onClick={() => handleDelete(row.entry_id)}
                  >
                    <span className="btn-inner">{TableIcons.remove()}</span>
                  </a>
                )}
                {viewPermission && (
                  <HiMiniViewfinderCircle
                    style={{ fontSize: "1.8rem", marginRight: "5px" }}
                    className="btn btn-sm btn-icon btn-warning mr-2"
                    onClick={() => handleView(row.entry_id)}
                  />
                )}
              </>
            ) : (
              <>
                {restorePermission && (
                  <LuDatabaseBackup
                    style={{ fontSize: "1.8rem", marginRight: "5px" }}
                    className="btn btn-sm btn-icon btn-warning mr-2"
                    onClick={() => handleRestore(row.entry_id)}
                  />
                )}
                {purgePermission && (
                  <BsDatabaseFillX
                    style={{ fontSize: "1.8rem", marginRight: "5px" }}
                    className="btn btn-sm btn-icon btn-danger mr-2"
                    onClick={() => handlePrune(row.entry_id)}
                  />
                )}
              </>
            )}
          </div>
        ),
      },
    ],
    [hideColumns],
  );
  // Function to handle column visibility
  const handleHideColumnsChange = (columnId, value) => {
    setHideColumns({
      ...hideColumns,
      [columnId]: !value
    });
  };

  // Data filter and search related functions
  // Get query string for DataStatus
  const getDataStatusQuery = (e) => {
    let is_del_value = `is_del=${searchFilter.dataStatus === "Only Live"
      ? 0
      : searchFilter.dataStatus === "Deleted Only"
        ? 1
        : 2
      }`;
    return is_del_value;
  };

  const getFilterQueryString = (key, value, index) => {
    const mapping = filterQueryMapping[key];
    if (mapping === "like") {
      const values = Array.isArray(value) ? value.join(",") : value;
      return `${mapping}[${index}]=${key},${values}`;
    } else if (mapping === "in") {
      const values = value.map((item) => item.value).join(",");
      return `${mapping}=${key},${values}`;
    }
  }

  const getSearchFilterQuery = () => {
    const queryParams = [];
    let index = 0;

    for (const key in searchFilter) {
      if (key == 'dataStatus') continue;
      let value = searchFilter[key];
      if (key == 'searchKey' && value !== "") {
        // remove white spaces in the searchKey
        value = value.replace(/\s/g, '');
        // create the query string over here
        return `&like[0]=mistake_type,${value}&like[1]=pervious_data,${value}&like[2]=current_data,${value}&like[3]=remarks,${value}&like[4]=data_date,${value}&like[5]=entry_date,${value}&like[6]=approval_date,${value}&like[7]=approved_by,${value}&like[8]=approval_status,${value}`
      }
      // Check if the value is defined and not an empty array
      if (value && (Array.isArray(value) ? value.length > 0 : true)) {
        queryParams.push(getFilterQueryString(key, value, index));
        if (filterQueryMapping[key] === "like") {
          ++index;
        }
      }
    }
    if (queryParams.length)
      return "&" + `${queryParams.join("&")}`;
    return "";
  };

  const getDataList = (page, rowsPerPage) => {
    setIsLoading(true);
    let url = `page=${page}&pp=${rowsPerPage}&${getDataStatusQuery()}${getSearchFilterQuery()}`;
    mistakeTicketService
      .search(url)
      .then((response) => {
        console.log(response);
        console.log(response.data.data);
        setData(response.data.data);
        setTotalRows(response.data.meta.total);
        setIsLoading(false);
      })
      .catch((error) => {
        showToast(error.response.data.message, "error");
        setIsLoading(false);
      });
  }
  // Upd ate this function to handle the switching between searchKey and search filter parameters
  const handleSearchFilter = (value) => {
    // when keysearch is pressed, set the searchKey and clean others
    // when filtersearch is pressed, clear the search key and set others
    if (value === "keySearch") {
      setSearchFilter(
        {
          ...searchFilter,
          ticket_number: "",
          mistake_type: [],
          pervious_data: "",
          current_data: "",
          remarks: "",
          data_date: "",
          entry_date: "",
          approval_date: "",
          approval_status: [],
          status: [],
        }
      );
    } else {
      setSearchFilter({
        ...searchFilter,
        searchKey: "",
      });
    }
    sessionStorage.setItem("mistake_ticket_filter", JSON.stringify(searchFilter));
    getDataList(1, perPage);
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.deleted_at,
      style: {
        backgroundColor: "#FFCCCB",
      },
    },
    //we can add more conditions with style
  ];

  const handleInputChange = (name, value) => {
    setSearchFilter({
      ...searchFilter,
      [name]: value,
    });
  };

  const handleExport = () => {

  };

  const [selectedRows, setSelectedRows] = useState([]);

  // This function handle on select
  const handleOnSelect = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const selectedRowsUid = [];
  selectedRows.map((row) => {
    selectedRowsUid.push(row.entry_id);
  });

  // handle delete function
  const handleDelete = (uuid) => {
    Swal.fire({
      title: "Are you sure you want to delete the mistake ticket?",
      text: "You might not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      backdrop: `rgba(60,60,60,0.8)`,
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.value) {
        setIsLoading(true);
        mistakeTicketService
          .delete(uuid)
          .then((response) => {
            setIsLoading(false);
            getDataList(currentPage, perPage);
            Swal.fire("Mistake Ticket Deleted", "The selected mistake ticket has been marked deleted.", "success");
          })
          .catch((error) => {
            setIsLoading(false);
            showToast(error.response.data.message, "error");
          });
        setIsLoading(false);
      }
    });
  };
  // handle restore function
  const handleRestore = (uuid) => {
    Swal.fire({
      title: "Continue restoring the mistake ticket?",
      text: "You are about to restore the mistake ticket marked as deleted",
      icon: "information",
      showCancelButton: true,
      backdrop: `rgba(60,60,60,0.8)`,
      confirmButtonText: "Yes, Restore it!",
    }).then((result) => {
      if (result.value) {
        setIsLoading(true);
        mistakeTicketService
          .restore(uuid)
          .then((response) => {
            setIsLoading(false);
            getDataList(currentPage, perPage);
            Swal.fire("Restore Completed", "Selected mistake ticket has been restored.", "success");
          })
          .catch((error) => {
            setIsLoading(false);
            showToast(error.response.data.message, "error");
          });
        setIsLoading(false);
      }
    });
  };
  const handlePrune = (uuid) => {
    Swal.fire({
      title: "Are you sure you want to delete the mistake ticket permanently?",
      text: "You won't be able to recover the data back!",
      icon: "danger",
      showCancelButton: true,
      backdrop: `rgba(60,60,60,0.8)`,
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.value) {
        setIsLoading(true);
        mistakeTicketService
          .prune(uuid)
          .then((response) => {
            setIsLoading(false);
            getDataList(currentPage, perPage);
            Swal.fire("Mistake Ticket Deleted", "Selected Mistake Ticket has been deleted permanently.", "success");
          })
          .catch((error) => {
            setIsLoading(false);
            showToast(error.response.data.message, "error");
          });
        setIsLoading(false);
      }
    });
  };
  const handleEdit = (value) => {
    navigate(`/app/mistake_ticket/edit/${value}`);
  };
  const handleMultipleDelete = () => {
    Swal.fire({
      title: "Are you sure you want to mark selected mistake tickets as deleted?",
      text: "You may not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      backdrop: `rgba(60,60,60,0.8)`,
      confirmButtonText: "Yes, Mark deleted!",
    }).then((result) => {
      if (result.value) {
        setIsLoading(true);
        mistakeTicketService
          .deleteAll(selectedRowsUid)
          .then((response) => {
            setIsLoading(false);
            getDataList(currentPage, perPage);
            Swal.fire("Mistake Tickets Marked Deleted!", "Selected mistake tickets has been marked as deleted.", "success");
          })
          .catch((error) => {
            setIsLoading(false);
            showToast(error.response.data.message, "error");
          });
        setIsLoading(false);
      }
    });
  };

  const handleMultiplePurge = () => {
    Swal.fire({
      title: "Are you sure you want to permenantly delete selected mistake tickets?",
      text: "You won't be able to revert this!",
      icon: "danger",
      showCancelButton: true,
      backdrop: `rgba(60,60,60,0.8)`,
      confirmButtonText: "Yes, Delete Permanently!",
    }).then((result) => {
      if (result.value) {
        setIsLoading(true);
        mistakeTicketService
          .pruneAll(selectedRowsUid)
          .then((response) => {
            setIsLoading(false);
            getDataList(currentPage, perPage);
            Swal.fire("Mistake Tickets Deleted", "Selected mistake tickets has been deleted permanently", "success");
          })
          .catch((error) => {
            setIsLoading(false);
            showToast(error.response.data.message, "error");
          });
        setIsLoading(false);
      }
    });
  };

  const handleMultipleRestore = () => {
    Swal.fire({
      title: "Are you sure you want to restore mistake tickets?",
      text: "You want to restore deleted mistake tickets!",
      icon: "info",
      showCancelButton: true,
      backdrop: `rgba(60,60,60,0.8)`,
      confirmButtonText: "Yes, Restore Mistake Tickets!",
    }).then((result) => {
      if (result.value) {
        setIsLoading(true);
        mistakeTicketService
          .restoreAll(selectedRowsUid)
          .then((response) => {
            setIsLoading(false);
            getDataList(currentPage, perPage);
            Swal.fire("Mistake Tickets Restored!", "Selected deleted mistake tickets has been restored", "success");
          })
          .catch((error) => {
            setIsLoading(false);
            showToast(error.response.data.message, "error");
          });
        setIsLoading(false);
      }
    });
  };
  const handleView = (value) => {
    navigate(`/app/mistake_ticket/view/${value}`);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchFilter("keySearch");
    }
  }

  useEffect(() => {
    getDataList(currentPage, perPage);
    setPageTitle('Mistake Tickets');
  }, [currentPage, perPage]);


  return (
    <>
      <Fragment>
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">Mistake Ticket List</h4>
            </div>
            <span>
              {createPermission && (
                <Button variant="outline-primary" onClick={() => navigate("/app/mistake_ticket/add")}><AiFillPlusCircle style={{ fontSize: "1.3rem" }} /> Add New</Button>
              )}
              {/* <Button variant="outline-primary" onClick={() => handleExport()}><AiFillPlusCircle style={{ fontSize: "1.3rem" }} /> Export</Button> */}
            </span>
          </Card.Header>
          <Card.Body>
            <DataTable
              columns={tableColumns}
              data={data}
              responsive
              pagination
              paginationServer
              debugger
              paginationPerPage={perPage}
              paginationComponentOptions={{
                selectAllRowsItem: true,
                selectAllRowsItemText: "All",
              }}
              paginationRowsPerPageOptions={[10, 20, 30, 50]}
              paginationServerPage={currentPage}
              paginationTotalRows={totalRows}
              onChangePage={(page) => {
                setCurrentPage(page);
              }}
              onChangeRowsPerPage={(newPerPage, page) => {
                setPerPage(newPerPage === "All" ? data.length : newPerPage);
                setCurrentPage(page);
              }}
              selectableRows
              className=""
              theme={dtColorMode == "dark" ? "dark" : ""}
              selectableRowsHighlight
              highlightOnHover
              onSelectedRowsChange={handleOnSelect}
              fixedHeader
              conditionalRowStyles={conditionalRowStyles} //Apply conditional style
              subHeader
              subHeaderComponent={
                <>
                  <Col md="12">
                    <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                      <div className="d-flex gap-2 flex-wrap align-items-center mb-3">
                        <Form
                          onKeyPress={handleKeyPress}
                          className="d-flex gap-2 align-items-center"
                        >
                          <Form.Control
                            name="searchKey"
                            style={{ width: "200px" }}
                            type="text"
                            placeholder="Search"
                            value={searchFilter.searchKey}
                            onChange={(e) => {
                              handleInputChange(e.target.name, e.target.value);
                            }}
                          />
                          <a className="btn btn-sm btn-outline-secondary d-flex p-2"
                            onClick={() => handleSearchFilter("keySearch")}
                          >
                            <FaSearch style={{ fontSize: "1.3rem" }} />
                          </a>
                          <a className="btn btn-sm btn-outline-secondary d-flex p-2"
                            onClick={() => setShowOffcanvas(!showOffcanvas)} // Toggle the Offcanvas
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                          >
                            <AiFillFunnelPlot
                              style={{ fontSize: "1.3rem" }}
                            />
                          </a>

                        </Form>
                      </div>
                      <div className="mb-3  d-flex gap-1 flex-wrap align-items-center">
                        {deletePermission && (
                          <a className="btn btn-sm btn-outline-warning d-flex p-2"
                            disabled={selectedRows.length > 0 ? false : true}
                            onClick={() => handleMultipleDelete()}
                          >
                            <AiFillDelete style={{ fontSize: "1.3rem" }} />
                          </a>
                        )}
                        {restorePermission && (
                          <a className="btn btn-sm btn-outline-info d-flex p-2"
                            disabled={selectedRows.length > 0 ? false : true}
                            onClick={() => handleMultipleRestore()}
                          >
                            <LuDatabaseBackup style={{ fontSize: "1.3rem" }} />
                          </a>
                        )}
                        {purgePermission && (
                          <a className="btn btn-sm btn-outline-danger d-flex p-2"
                            disabled={selectedRows.length > 0 ? false : true}
                            onClick={() => handleMultiplePurge()}
                          >
                            <BsDatabaseFillX style={{ fontSize: "1.3rem" }} />
                          </a>
                        )}
                      </div>
                      <MistakeTicketFilter
                        // handleExport={handleExport}
                        tableColumns={tableColumns}
                        showOffcanvas={showOffcanvas}
                        searchFilter={searchFilter}
                        handleOffcanvasToggle={handleOffcanvasToggle}
                        handleHideColumnsChange={handleHideColumnsChange}
                        handleInputChange={handleInputChange}
                        handleSearchFilter={handleSearchFilter} />
                    </div>
                  </Col>
                </>
              }
            />
          </Card.Body>
        </Card>
      </Fragment >
    </>
  );
});

MistakeTicket.displayName = "MistakeTicket";
export default MistakeTicket;
