import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Collapse,
  ListGroup,
  ListGroupItem,
  Offcanvas,
} from "react-bootstrap";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import Select from "react-select";
import attributeService from "../../services/attribute.service";
import { useStateContext } from "../../context/ContextProvider";

export default function MistakeTicketFilter(props) {

  const { showToast } = useStateContext();

  // OffCanvas settings and functions
  const [openDisplay, setOpenDisplay] = useState(false);
  const [openFilter, setOpenFilter] = useState(true);
  // Status select list options
  const statusOptions = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  // Approval options
  const approvalOptions = [
    { value: "Done", label: "Done" },
    { value: "Pending", label: "Pending" },
    { value: "Rejected", label: "Rejected" },
  ];

  const displayError = (error) => {
    let errArr = [];
    errArr = Object.keys(error).map((key) => {
      return error[key].join(",");
    });
    errArr.map((column) =>
      showToast(column, "error")
    );
  }

  const [mistakeTypeOptions, setMistakeTypeOptions] = useState();

  //get the mistake type
  const getMistakeType = () => {
    attributeService.childrenList('Mistake Type')
      .then((response) => {
        setMistakeTypeOptions(response.data);
      })
      .catch((error) => {
        displayError(error.response.data.errors);
      })
  }
  const mistakeType = mistakeTypeOptions ? (
    mistakeTypeOptions.map((types) => ({
      value: types.text,
      label: types.text,
    }))
  ) : (
    <>none</>
  );

  const handleApprovalStatusChange = (selectedOptions) => {
    props.handleInputChange("approval_status", selectedOptions);
  };

  const handleMistakeTypeChange = (selectedOptions) => {
    props.handleInputChange("mistake_type", selectedOptions);
  };

  useEffect(() => {
    getMistakeType();
  }, []);

  return (
    <Offcanvas
      show={props.showOffcanvas} // Control Offcanvas visibility
      onHide={props.handleOffcanvasToggle} // Close Offcanvas when clicking the backdrop
      placement="end"
      target="offcanvasRight"
      tabindex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <Offcanvas.Header class="offcanvas-header">
        <Offcanvas.Title id="offcanvasRightLabel">
          Advanced Filters and Display
        </Offcanvas.Title>
        <button
          type="button"
          class="btn-close"
          onClick={() => {
            props.handleOffcanvasToggle();
          }}
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Button
          href="#"
          onClick={() => setOpenDisplay(!openDisplay)}
          aria-controls="example-collapse-text"
          aria-expanded={openDisplay}
          bsPrefix="bg-transparent iq-custom-collapse w-100 d-flex justify-content-between pb-3"
        >
          <h5 className="mb-0">
            Show/Hide Columns{" "}
            {openDisplay ? (
              <IoIosArrowDropdownCircle style={{ fontSize: "1.5rem" }} />
            ) : (
              <IoIosArrowDroprightCircle style={{ fontSize: "1.5rem" }} />
            )}
          </h5>
        </Button>
        <Collapse in={openDisplay}>
          <div id="iq-product-filter-01">
            <div className="mt-2">
              <div className="product-ratings mt-2">
                <ListGroup>
                  {props.tableColumns.map((column) => {
                    if (
                      column.id === "sno" ||
                      column.id === "status" ||
                      column.id === "actions"
                    ) {
                      return null;
                    }
                    return (
                      <div key={column.id}>
                        <ListGroupItem as="label" className="border-0">
                          <input
                            className="form-check-input m-1"
                            type="checkbox"
                            defaultChecked={!column.omit}
                            onChange={() =>
                              props.handleHideColumnsChange(
                                column.id,
                                column.omit
                              )
                            }
                          />
                          {column.name}
                        </ListGroupItem>
                      </div>
                    );
                  })}
                </ListGroup>
              </div>
            </div>
          </div>
        </Collapse>
        <Button
          href="#"
          onClick={() => setOpenFilter(!openFilter)}
          aria-controls="example-collapse-text"
          aria-expanded={openFilter}
          bsPrefix="bg-transparent iq-custom-collapse w-100 d-flex justify-content-between pb-3"
        >
          <h5 className="mb-0 mt-3">
            Filters
            {openFilter ? (
              <IoIosArrowDropdownCircle style={{ fontSize: "1.5rem" }} />
            ) : (
              <IoIosArrowDroprightCircle style={{ fontSize: "1.5rem" }} />
            )}
          </h5>
        </Button>
        <Collapse in={openFilter}>
          <div id="iq-product-filter-01">
            <div className="mt-2">
              <div className="product-ratings m-2">
                <Form onSubmit={() => props.handleSearchFilter("filterSearch")}>
                  <Form.Label htmlFor="validationCustom01">
                    <h5>Type of Data</h5>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    className="form-select"
                    name="dataStatus"
                    aria-label=".form-select example"
                    onChange={(e) => {
                      props.handleInputChange(e.target.name, e.target.value);
                    }}
                    defaultValue={"is_del=0"}
                    value={props.searchFilter.dataStatus}
                  >
                    <option defaultValue="is_del=0">Only Live</option>
                    <option defaultValue="is_del=1">Deleted Only</option>
                    <option defaultValue="is_del=2">Display All</option>
                  </Form.Control>

                  <Form.Label htmlFor="validationCustom01">
                    <h6>Ticket Number</h6>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="ticket_number"
                    name="ticket_number"
                    value={props.searchFilter.ticket_number}
                    onChange={(e) => {
                      props.handleInputChange(e.target.name, e.target.value);
                    }}
                  />
                  <Form.Label htmlFor="validationCustom01">
                    <h6>Mistake Type</h6>
                  </Form.Label>
                  <Select
                    isMulti
                    name="mistake_type"
                    options={mistakeType}
                    value={props.searchFilter.mistake_type}
                    onChange={handleMistakeTypeChange}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                  <Form.Label htmlFor="validationCustom01">
                    <h6>Pervious Data</h6>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="pervious_data"
                    name="pervious_data"
                    value={props.searchFilter.pervious_data}
                    onChange={(e) => {
                      props.handleInputChange(e.target.name, e.target.value);
                    }}
                  />

                  <Form.Label htmlFor="validationCustom01">
                    <h6>Current Data</h6>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="current_data"
                    value={props.searchFilter.current_data}
                    onChange={(e) => {
                      props.handleInputChange(e.target.name, e.target.value);
                    }}
                  />
                  <Form.Label htmlFor="validationCustom01">
                    <h6>Remarks</h6>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="remarks"
                    value={props.searchFilter.remarks}
                    onChange={(e) => {
                      props.handleInputChange(e.target.name, e.target.value);
                    }}
                  />
                  <Form.Label htmlFor="validationCustom01">
                    <h6>Data Date</h6>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="data_date"
                    value={props.searchFilter.data_date}
                    onChange={(e) => {
                      props.handleInputChange(e.target.name, e.target.value);
                    }}
                  />
                  <Form.Label htmlFor="validationCustom01">
                    <h6>Entry Date</h6>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="entry_date"
                    value={props.searchFilter.entry_date}
                    onChange={(e) => {
                      props.handleInputChange(e.target.name, e.target.value);
                    }}
                  />
                  <Form.Label htmlFor="validationCustom01">
                    <h6>Approval Date</h6>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="approval_date"
                    value={props.searchFilter.approval_date}
                    onChange={(e) => {
                      props.handleInputChange(e.target.name, e.target.value);
                    }}
                  />
                  <Form.Label htmlFor="validationCustom01">
                    <h6>Approval Status</h6>
                  </Form.Label>
                  <Select
                    isMulti
                    name="approval_status"
                    options={approvalOptions}
                    value={props.searchFilter.approval_status}
                    onChange={handleApprovalStatusChange}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </Form>
              </div>
            </div>
          </div>
        </Collapse>
      </Offcanvas.Body>
      <Button
        variant="outline-secondary"
        className="m-3 mb-0"
        type="submit"
        onClick={() => props.handleSearchFilter("filterSearch")}
      >
        Apply Filters
      </Button>
      {/* <Button
        className="m-3"
        variant="outline-info"
        onClick={() => props.handleExport()}
      >
        Export
      </Button> */}
    </Offcanvas>
  );
}
