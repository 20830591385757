import { useFormik } from "formik";
import React, { useState, useEffect, Fragment } from "react";
import {
    Button,
    Card,
    Col,
    Form,
    Row,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { AiFillDelete, AiFillPlusCircle } from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import { useStateContext } from "../../context/ContextProvider";
import { usePermissionContext } from "../../context/PremissionContext";
import rawMaterialService from "../../services/raw-material.service";
import warehouseService from "../../services/warehouse.service";
import itemChallanDetailService from "../../services/item-challan-detail.service";
import itemChallanService from "../../services/item-challan.service";
import { ADToBS } from "bikram-sambat-js";

const OpItemChallanOutDetail = ({ challan_data }) => {
    const { id, type } = useParams();
    const navigate = useNavigate();
    const { permissions } = usePermissionContext();
    const {
        dtColorMode,
        setIsLoading,
        setPageTitle,
        showToast,
        setRefereshData,
        refereshData,
    } = useStateContext();

    const createPermission = permissions.some(permission => permission.name === 'Create Item Challan');
    const updatePermission = permissions.some(permission => permission.name === 'Update Item Challan');
    const viewPermission = permissions.some(permission => permission.name === 'View Item Challan');
    const deletePermission = permissions.some(permission => permission.name === 'Delete Item Challan');
    const restorePermission = permissions.some(permission => permission.name === 'Restore Item Challan');
    const purgePermission = permissions.some(permission => permission.name === 'Purge Item Challan');

    const displayError = (error) => {
        const errArr = Object.keys(error).map((key) => error[key].join(","));
        errArr.forEach((column) => showToast(column, "error"));
    };

    const [data, setData] = useState([]);

    useEffect(() => {
        getItemList();
        getWarehouseList();
    }, [refereshData]);

    const [forms, setForms] = useState([{
        item_id: '',
        available: '',
        quantity: ''
    }]);

    const addForm = () => {
        setForms([...forms, {
            item_id: '',
            available: '',
            quantity: ''
        }]);
    };

    const removeForm = (index) => {
        setForms(forms.filter((_, i) => i !== index));
    };

    const handleChange = (index, e) => {
        const { name, value } = e.target;
        const newForms = [...forms];
        newForms[index] = { ...newForms[index], [name]: value };
        setForms(newForms);
    };

    const handleBlur = (index, e) => {
        // Handle onBlur if necessary
    };

    const handleItemChange = (index, selectedOption) => {
        const newForms = [...forms];
        newForms[index] = { ...newForms[index], item_id: selectedOption.value };
        setForms(newForms);
    };

    const handleSubmitAll = () => {
        setIsLoading(true);
        if (forms.length > 0) {
            itemChallanService.storeAllOut({
                main: {
                    ...challan_data,
                    np_challan_date: ADToBS(challan_data.challan_date),
                },
                items: forms,
                warehouse_id: warehouse
            }).then((response) => {
                setIsLoading(false);
                navigate('/app/operation/dashboard');
            }).catch((error) => {
                setIsLoading(false);
                displayError(error.response.data.errors);
            })
        } else {
            setIsLoading(false);
            showToast("No data to submit", "warning");
        }
    };

    // To fetch the details
    const [warehouse, setWarehouse] = useState();
    const handleWarehouseChange = (event) => {
        setWarehouse(event.target.value);
    };

    const [itemList, setItemList] = useState([]);
    const [warehouseList, setWarehouseList] = useState([]);
    const [itemRemaining, setItemRemaining] = useState([]);
    const [quantityLimit, setQuantityLimit] = useState();

    const getItemList = () => {
        rawMaterialService.lists()
            .then((response) => {
                setItemList(response.data.data);
            })
            .catch((error) => {
                displayError(error.response.data.errors);
            })
    };

    const getWarehouseList = () => {
        warehouseService.lists()
            .then((response) => {
                setWarehouseList(response.data.data);
                setWarehouse(response.data.data[0].id);
            })
            .catch((error) => {
                displayError(error.response.data.errors);
            })
    };

    const itemAvailable = (index) => {
        setIsLoading(true);
        const form = forms[index];
        if (warehouse && form.item_id) {
            const values = {
                warehouse_id: warehouse,
                item_id: form.item_id
            };
            itemChallanDetailService.itemList(values)
                .then((response) => {
                    const newForms = [...forms];
                    newForms[index] = {
                        ...newForms[index],
                        available: response.data.data[0].quantity_difference
                    };
                    setForms(newForms);
                    setIsLoading(false);
                })
                .catch((error) => {
                    displayError(error.response.data.errors);
                    setIsLoading(false);
                });
        } else {
            showToast("Please select both warehouse and item", "error");
            setIsLoading(false);
        }
    };

    const formik = useFormik({
        initialValues: data
    });

    const itemOptions = itemList.map((item) => ({
        value: item.id,
        label: item.text
    }));

    return (
        <Fragment>
            <Card>
                <Card.Body>
                    <div>
                        {forms.map((form, index) => (
                            <Form key={index} noValidate>
                                <Row>
                                    <Col md="5">
                                        <Form.Label htmlFor="validationCustom01" className="h6">
                                            Item Name
                                        </Form.Label>
                                        <Select
                                            options={itemOptions}
                                            value={itemOptions.find(option => option.value === form.item_id)}
                                            onChange={(selectedOption) => handleItemChange(index, selectedOption)}
                                            onBlur={formik.handleBlur}
                                            className={`form-control p-0`}
                                        />
                                    </Col>
                                    <Col md="4">
                                        <Form.Label htmlFor={`quantity_${index}`} className="h6">
                                            Quantity
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            id={`quantity_${index}`}
                                            name="quantity"
                                            value={form.quantity}
                                            onChange={(e) => handleChange(index, e)}
                                            onBlur={(e) => handleBlur(index, e)}
                                        />
                                    </Col>
                                    <Col className="d-flex justify-content-end p-2 mt-3 gap-2">
                                        <Button variant="btn btn-outline-danger" onClick={() => removeForm(index)}>
                                            <AiFillDelete style={{ fontSize: "1.3rem" }} />
                                        </Button>
                                        <Button variant="btn btn-outline-primary" onClick={addForm}>
                                            <AiFillPlusCircle style={{ fontSize: "1.3rem" }} />
                                            Add New
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        ))}
                        <div className="mt-3 text-end">
                            <Button onClick={handleSubmitAll}>Submit All Data</Button>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Fragment >
    );
};

export default OpItemChallanOutDetail;
